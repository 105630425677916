import React from 'react';
import useSWR from 'swr';
import { Avatar, Flex, Box } from 'monday-ui-react-core';
import TimeAgo from 'react-timeago';
import { userBatchResolver } from '../hooks/use-user';

type CreatedByProps = {
  userId: number;
  time: number;
  userAvatar: boolean;
};

export default function CreatedBy({ userId, time, userAvatar }: CreatedByProps) {
  const {
    data: user,
    isLoading,
    error,
  } = useSWR(`/created-by/${userId}`, async () => userBatchResolver.resolveItem(userId), {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error || !user) {
    return <div>Failed to load user</div>;
  }

  return (
    <>
      {userAvatar ? (
        <>
          <Avatar type={Avatar.types?.IMG as any} size={Avatar.sizes?.MEDIUM as any} withoutBorder={true} src={user.photo_small} />
        </>
      ) : (
        <>
          <Box marginStart={Box.marginStarts?.XS as any}>
            <Flex gap={Flex.gaps?.SMALL as any}>
              <div
                style={{
                  fontSize: '12px',
                  color: '#afb0bd',
                }}
              >
                Created <TimeAgo date={time} /> by {user.name}
              </div>
            </Flex>
          </Box>
        </>
      )}
    </>
  );
}
