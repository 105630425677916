import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import 'monday-ui-react-core/dist/main.css';
import {
  Button,
  Box,
  Flex,
  Heading,
  Icon,
  Divider,
  Label,
  Avatar,
  IconButton,
  Counter,
  Tipseen,
  TipseenWizard,
  TipseenContent,
  TipseenMedia,
  TipseenImage,
} from 'monday-ui-react-core';
// @ts-ignore
import ExternalPage from 'monday-ui-react-core/dist/icons/ExternalPage';
// @ts-ignore
import NavigationChevronUpIcon from 'monday-ui-react-core/dist/icons/NavigationChevronUp';
// @ts-ignore
import NavigationChevronDownIcon from 'monday-ui-react-core/dist/icons/NavigationChevronDown';
// @ts-ignore
import IdeaIcon from 'monday-ui-react-core/dist/icons/Idea';
// @ts-ignore
import TableIcon from 'monday-ui-react-core/dist/icons/Table';
// @ts-ignore
import UploadIcon from 'monday-ui-react-core/dist/icons/Upload';
// @ts-ignore
import LayoutIcon from 'monday-ui-react-core/dist/icons/Layout';
// @ts-ignore
import PlayIcon from 'monday-ui-react-core/dist/icons/Play';
// @ts-ignore
import FeedbackIcon from 'monday-ui-react-core/dist/icons/Feedback';
// @ts-ignore
import BugIcon from 'monday-ui-react-core/dist/icons/Bug';
// @ts-ignore
import BoltIcon from 'monday-ui-react-core/dist/icons/Bolt';
// @ts-ignore
import AcademyIcon from 'monday-ui-react-core/dist/icons/Academy';
// @ts-ignore
import MondayLogoOutlineIcon from 'monday-ui-react-core/dist/icons/MondayLogoOutline';
// @ts-ignore
import CalendarIcon from 'monday-ui-react-core/dist/icons/Calendar';
// @ts-ignore
import FormIcon from 'monday-ui-react-core/dist/icons/Form';
import { useNavigate } from 'react-router-dom';
import { getSubscription } from '@gorilla/common/src/lib/monday-api/api';
import { getTemplates, createTemplate, deleteTemplate, getTemplate } from '../../services/templates';
import { getExecutions } from '../../services/executions';
import { getSpreadsheetsIndex, deleteSpreadsheet, SpreadsheetsIndexEntry } from '../../services/spreadsheets';
import { mondayClient } from '../../services/monday';
import { createSignal } from '../../services/signals';
import { TemplatesList } from '../../components/TemplatesList';
import { SpreadsheetsList } from '../../components/SpreadsheetsList';
import { CreateSpreadsheetModal } from '../../components/CreateSpreadsheetModal';
import Loading from '../../components/Loading';
import ErrorMessage from '../../components/ErrorMessage';
import { ErrorScreen } from '../../components/ErrorScreen';
import { useAllBoards } from '../../shared/hooks/use-boards';
import useMonday from '../../hooks/use-monday';
import { getDefaultTemplate } from '../../hooks/use-spreadsheet-template';
import { ExecutionsSummary } from '../../shared/components/ExecutionsSummary';
import { FormulaDocumentationModal } from '../../components/FormulaDocumentationModal';
import './HomePage.css';
import { IntroductionModal } from '../../components/IntroductionModal';
import { SpreadsheetTemplatesWhatsThisDialog, SpreadsheetsWhatsThisDialog } from '../../components/WhatsThisDialog';
import ScrollToTop from '../../shared/components/ScrollToTop';

const BUCKETS_TO_LIMIT = {
  // v1
  premium: 100,

  // v2
  small_v2: 100,
  medium_v2: 250,
  large_v2: 500,
};

function Onboarding({ title, description, children }) {
  return (
    <>
      <Tipseen
        width={330}
        position={Tipseen.positions.RIGHT}
        hideCloseButton
        content={<TipseenContent title={title}>{description}</TipseenContent>}
      >
        {children}
      </Tipseen>
    </>
  );
}

function HomePage() {
  const [showTemplates, setShowTemplates] = useState(true);
  const [showSpreadsheets, setShowSpreadsheets] = useState(false);
  const { data: templates, error, isLoading, mutate: mutateTemplates } = useSWR('/api/get-templates', async () => getTemplates());
  const { mondayContext, appMode, subscription } = useMonday();
  const planId = subscription?.plan_id || 'premium';
  const {
    data: executionsResult,
    error: executionsError,
    isLoading: isLoadingExecutions,
  } = useSWR('/api/get-executions', async () => {
    let pId = 'premium';

    try {
      const subscription = await getSubscription(mondayClient);
      pId = subscription?.plan_id || 'premium';
    } catch (err) {
      console.log('could not fetch subscription', err);
    }

    return getExecutions(pId);
  });
  const { boards, error: boardsError } = useAllBoards();
  const navigate = useNavigate();
  const [showCreateSpreadsheetModal, setShowCreateSpreadsheetModal] = useState<boolean | string>(false);
  const [showDocumentation, setShowDocumentation] = useState<boolean>(false);
  const [showIntroductionModal, setShowIntroductionModal] = useState<boolean>(window.localStorage.getItem('seen-introduction') !== 'yes');
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 1080);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1080);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const {
    data: spreadsheets,
    error: spreadsheetsError,
    isLoading: isLoadingSpreadsheets,
    mutate: mutateSpreadsheets,
  } = useSWR('/api/get-spreadsheets-index', async () => {
    const indexResponse = await getSpreadsheetsIndex();

    return indexResponse.value;
  });

  const {
    trigger: createTemplateTrigger,
    isMutating: isCreatingTemplate,
    error: createTemplateError,
  } = useSWRMutation('/api/create-template', async () => {
    const defaultTemplate = await getDefaultTemplate(mondayContext?.boardId!, true);
    return createTemplate(defaultTemplate);
  });

  const {
    trigger: duplicateTemplateTrigger,
    //isMutating: isDuplicatingTemplate,
    //error: duplicateTemplateError,
  } = useSWRMutation('/api/duplicate-template', async (url, { arg: template }: { arg: any }) => {
    const originalTemplate = await getTemplate(template.id);
    const newConfig = _.cloneDeep(originalTemplate.config);

    if (newConfig.messages) {
      // for now, don't copy messages
      newConfig.messages = [];
    }

    const newTemplate = {
      name: `${originalTemplate.name} (copy)`,
      type: 'spreadsheet',
      private: true,
      config: newConfig,
    };

    return createTemplate(newTemplate);
  });

  const { trigger: deleteTemplateTrigger, isMutating: isDeletingTemplate } = useSWRMutation(
    '/api/delete-template',
    (key, { arg: template }) => deleteTemplate(template)
  );

  const { trigger: deleteSpreadsheetTrigger, isMutating: isDeletingSpreadsheet } = useSWRMutation(
    '/api/delete-spreadsheet',
    (key, { arg: spreadsheet }) => deleteSpreadsheet((spreadsheet as SpreadsheetsIndexEntry).id)
  );

  if (appMode === 'beta_expired') {
    return <ErrorScreen message="The beta expired, please subscribe if you want to keep on using the app." />;
  }

  if (appMode === 'trial_expired') {
    return <ErrorScreen message="The trial expired, please subscribe if you want to keep on using the app." />;
  }

  if (appMode === 'expired') {
    return <ErrorScreen message="The subscription expired, please subscribe if you want to keep on using the app." />;
  }

  if (_.get(mondayContext, 'user.isGuest')) {
    return <ErrorScreen message="Guest mode currently not supported." />;
  }

  if (_.get(mondayContext, 'user.isViewOnly')) {
    return <ErrorScreen message="Viewers mode currently not supported." />;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (boardsError) {
    return <ErrorMessage error={boardsError} />;
  }

  if (createTemplateError) {
    return <ErrorMessage error={createTemplateError} />;
  }

  if (isLoading || !templates || isLoadingSpreadsheets || !boards || !mondayContext) {
    return <Loading />;
  }

  return (
    <div className="Home">
      <ScrollToTop />
      {showCreateSpreadsheetModal ? (
        <CreateSpreadsheetModal
          onClose={() => setShowCreateSpreadsheetModal(false)}
          show={!!showCreateSpreadsheetModal}
          initialCtx={_.isString(showCreateSpreadsheetModal) ? { formatId: showCreateSpreadsheetModal } : {}}
        />
      ) : null}
      {showDocumentation ? <FormulaDocumentationModal show={showDocumentation} onClose={() => setShowDocumentation(false)} /> : null}
      {showIntroductionModal ? (
        <IntroductionModal
          show={showIntroductionModal}
          onClose={() => {
            window.localStorage.setItem('seen-introduction', 'yes');
            setShowIntroductionModal(false);
          }}
        />
      ) : null}

      {(!templates || !templates.length) && (!spreadsheets || !spreadsheets.length) ? <></> : <></>}

      {/* <FeedbackBanner /> */}

      {executionsResult ? (
        <ExecutionsSummary executionsResult={executionsResult} bucket={planId} bucketsToLimit={BUCKETS_TO_LIMIT} defaultLimit={20} />
      ) : null}

      {/* HELP */}
      {/* 
      <div
        style={{
          margin: '32px 32px 32px 32px',
        }}
      >
        <Flex gap={32} align={Flex.align?.STRETCH} justify={Flex.justify?.STRETCH} direction={Flex.directions?.ROW}>
          <div
            style={{
              display: 'flex',
              alignItems: 'stretch',
              width: '50%',
            }}
          >
            <div
              style={{
                overflow: 'hidden',
                position: 'relative',
                padding: '32px',
                width: '100%',
                backgroundColor: 'rgb(0, 115, 234)',
                boxShadow: '0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
                color: '#ffffff',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  right: '20%',
                  bottom: '-50px',
                  color: 'rgba(255,255,255,0.05)',
                }}
              >
                <Icon icon={AcademyIcon} iconSize={150} ignoreFocusStyle clickable={false} />
              </div>
              <Flex gap={20} justify={Flex.justify?.CENTER} align={Flex.align?.END}>
                <div>
                  <Flex gap={15}>
                    <div>
                      <Flex gap={5}>
                        <Icon icon={AcademyIcon} iconSize={18} ignoreFocusStyle clickable={false} />
                        <span>Guide</span>
                      </Flex>
                      <h5 style={{ marginTop: '5px' }}>
                        Getting started with <br />
                        Spreadsheet Gorilla
                      </h5>
                    </div>
                  </Flex>
                </div>
                <div
                  style={{
                    marginLeft: 'auto',
                  }}
                >
                  <IconButton
                    icon={ExternalPage}
                    size={Button.sizes?.MEDIUM as any}
                    kind={Button.kinds?.SECONDARY as any}
                    color={Button.colors?.ON_PRIMARY_COLOR as any}
                    onClick={() => {
                      createSignal('get_started_blog');
                      window.open('https://getgorilla.app/blog/why-should-i-make-use-of-spreadsheet-gorilla-for-monday-com', '_blank');
                    }}
                  />
                </div>
              </Flex>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'stretch',
              width: '50%',
            }}
          >
            <div
              style={{
                overflow: 'hidden',
                position: 'relative',
                padding: '32px',
                width: '100%',
                backgroundColor: 'rgb(0, 115, 234)',
                boxShadow: '0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
                color: '#ffffff',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  right: '20%',
                  bottom: '-50px',
                  color: 'rgba(255,255,255,0.05)',
                }}
              >
                <Icon icon={PlayIcon} iconSize={150} ignoreFocusStyle clickable={false} />
              </div>
              <Flex gap={20} justify={Flex.justify?.CENTER} align={Flex.align?.END}>
                <div>
                  <Flex gap={15}>
                    <div>
                      <Flex gap={5}>
                        <Icon icon={PlayIcon} iconSize={18} ignoreFocusStyle clickable={false} />
                        <span>Tutorials</span>
                      </Flex>
                      <h5 style={{ marginTop: '5px' }}>
                        Watch all of our video <br />
                        tutorials on YouTube
                      </h5>
                    </div>
                  </Flex>
                </div>
                <div
                  style={{
                    marginLeft: 'auto',
                  }}
                >
                  <IconButton
                    icon={ExternalPage}
                    size={Button.sizes?.MEDIUM as any}
                    kind={Button.kinds?.SECONDARY as any}
                    color={Button.colors?.ON_PRIMARY_COLOR as any}
                    onClick={() => {
                      createSignal('tutorials');
                      window.open('https://www.youtube.com/playlist?list=PLNt6YgM15GuHMoUhBM0fH1Ji_ARxdiQ2p', '_blank');
                    }}
                  />
                </div>
              </Flex>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'stretch',
              width: '50%',
            }}
          >
            <div
              style={{
                overflow: 'hidden',
                position: 'relative',
                padding: '32px',
                width: '100%',
                backgroundColor: 'rgb(0, 115, 234)',
                boxShadow: '0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
                color: '#ffffff',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  right: '20%',
                  bottom: '-50px',
                  color: 'rgba(255,255,255,0.05)',
                }}
              >
                <Icon icon={BugIcon} iconSize={150} ignoreFocusStyle clickable={false} />
              </div>
              <Flex gap={20} justify={Flex.justify?.CENTER} align={Flex.align?.END}>
                <div>
                  <Flex gap={15}>
                    <div>
                      <Flex gap={5}>
                        <Icon icon={BugIcon} iconSize={18} ignoreFocusStyle clickable={false} />
                        <span>Changelog</span>
                      </Flex>
                      <h5 style={{ marginTop: '5px' }}>
                        Learn more on recent <br />
                        updates and bug fixes
                      </h5>
                    </div>
                  </Flex>
                </div>
                <div
                  style={{
                    marginLeft: 'auto',
                  }}
                >
                  <IconButton
                    icon={ExternalPage}
                    size={Button.sizes?.MEDIUM as any}
                    kind={Button.kinds?.SECONDARY as any}
                    color={Button.colors?.ON_PRIMARY_COLOR as any}
                    onClick={() => {
                      createSignal('changelog');
                      window.open('https://getgorilla.app/products/spreadsheet-gorilla/changelog', '_blank');
                    }}
                  />
                </div>
              </Flex>
            </div>
          </div>
        </Flex>
      </div>
*/}
      <div>
        <div
          style={{
            margin: '32px',
          }}
        >
          <Flex gap={0} align={Flex.align?.STRETCH} direction={Flex.directions?.ROW}>
            {/* QUICK ACTIONS */}

            <div
              style={{
                width: isMobile ? '100%' : '50%',
              }}
            >
              <div style={{ marginRight: '16px', height: '392px' }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '24px', height: '100%' }}>
                  <div
                    className="quickaction--item"
                    onClick={async () => {
                      const newTemplate = await createTemplateTrigger();

                      if (newTemplate) {
                        mutateTemplates(templates ? [...templates, newTemplate] : [newTemplate]);
                        navigate(`/template/${newTemplate.id}/edit`);
                      }
                    }}
                  >
                    {!templates || !templates.length ? (
                      <Onboarding
                        title="Create your first template"
                        description="Templates are configurable abstraction layers in between your boards and spreadsheets."
                      >
                        <>
                          <div
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: '50%',
                              transform: 'translate3d(-50%,-12px,0)',
                            }}
                          >
                            <Label text="Get started" color={Label.colors.PRIMARY} isAnimationDisabled={true} />
                          </div>
                          <Flex gap={16} justify={Flex.justify?.CENTER as any} direction={Flex.directions?.COLUMN as any}>
                            <Avatar
                              type={Avatar.types?.ICON as any}
                              size={Avatar.sizes?.MEDIUM as any}
                              withoutBorder={true}
                              icon={LayoutIcon}
                              customBackgroundColor="#0073ea"
                              square
                            />
                            <div>
                              <Heading type={Heading.types?.h4 as any} customColor="#0073ea" value="Create template" />
                              <div
                                style={{
                                  color: '#afb0bd',
                                }}
                              >
                                and configure a spreadsheet
                              </div>
                            </div>
                          </Flex>
                        </>
                      </Onboarding>
                    ) : (
                      <>
                        <div
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: '50%',
                            transform: 'translate3d(-50%,-12px,0)',
                          }}
                        >
                          <Label text="Get started" color={Label.colors.PRIMARY} isAnimationDisabled={true} />
                        </div>
                        <Flex gap={16} justify={Flex.justify?.CENTER as any} direction={Flex.directions?.COLUMN as any}>
                          <Avatar
                            type={Avatar.types?.ICON as any}
                            size={Avatar.sizes?.MEDIUM as any}
                            withoutBorder={true}
                            icon={LayoutIcon}
                            customBackgroundColor="#0073ea"
                            square
                          />
                          <div>
                            <Heading type={Heading.types?.h4 as any} customColor="#0073ea" value="Create template" />
                            <div
                              style={{
                                color: '#afb0bd',
                              }}
                            >
                              and configure a spreadsheet
                            </div>
                          </div>
                        </Flex>
                      </>
                    )}
                  </div>
                  <div
                    className={!templates || !templates.length ? 'quickaction--item quickaction--disabled' : 'quickaction--item'}
                    onClick={async () => {
                      setShowCreateSpreadsheetModal(true);
                    }}
                  >
                    <Flex gap={16} justify={Flex.justify?.CENTER as any} direction={Flex.directions?.COLUMN as any}>
                      <Avatar
                        type={Avatar.types?.ICON as any}
                        size={Avatar.sizes?.MEDIUM as any}
                        withoutBorder={true}
                        icon={TableIcon}
                        customBackgroundColor={!templates || !templates.length ? '#d0d4e4' : '#0073ea'}
                        square
                      />
                      <div>
                        <Heading
                          type={Heading.types?.h4 as any}
                          customColor={!templates || !templates.length ? '#d0d4e4' : '#0073ea'}
                          value="Create spreadsheet"
                        />
                        {!templates || !templates.length ? (
                          <div style={{ color: '#e4e5eb' }}>from a spreadsheet template</div>
                        ) : (
                          <div style={{ color: '#afb0bd' }}>from a spreadsheet template</div>
                        )}
                      </div>
                    </Flex>
                  </div>
                  <div
                    className={!templates || !templates.length ? 'quickaction--item quickaction--disabled' : 'quickaction--item'}
                    onClick={async () => {
                      setShowCreateSpreadsheetModal('excel');
                    }}
                  >
                    <Flex gap={16} justify={Flex.justify?.CENTER as any} direction={Flex.directions?.COLUMN as any}>
                      <Avatar
                        type={Avatar.types?.ICON as any}
                        size={Avatar.sizes?.MEDIUM as any}
                        withoutBorder={true}
                        icon={TableIcon}
                        customBackgroundColor={!templates || !templates.length ? '#d0d4e4' : '#0073ea'}
                        square
                      />
                      <div>
                        <Heading
                          type={Heading.types?.h4 as any}
                          customColor={!templates || !templates.length ? '#d0d4e4' : '#0073ea'}
                          value="Export to Excel"
                        />
                        {!templates || !templates.length ? (
                          <div style={{ color: '#e4e5eb' }}>manually and download</div>
                        ) : (
                          <div style={{ color: '#afb0bd' }}>manually and download</div>
                        )}
                      </div>
                    </Flex>
                  </div>
                  <div
                    className={!templates || !templates.length ? 'quickaction--item quickaction--disabled' : 'quickaction--item'}
                    onClick={async () => {
                      setShowCreateSpreadsheetModal('csv');
                    }}
                  >
                    <Flex gap={16} justify={Flex.justify?.CENTER as any} direction={Flex.directions?.COLUMN as any}>
                      <Avatar
                        type={Avatar.types?.ICON as any}
                        size={Avatar.sizes?.MEDIUM as any}
                        withoutBorder={true}
                        icon={TableIcon}
                        customBackgroundColor={!templates || !templates.length ? '#d0d4e4' : '#0073ea'}
                        square
                      />
                      <div>
                        <Heading
                          type={Heading.types?.h4 as any}
                          customColor={!templates || !templates.length ? '#d0d4e4' : '#0073ea'}
                          value="Export to CSV"
                        />
                        {!templates || !templates.length ? (
                          <div style={{ color: '#e4e5eb' }}>manually and download</div>
                        ) : (
                          <div style={{ color: '#afb0bd' }}>manually and download</div>
                        )}
                      </div>
                    </Flex>
                  </div>
                  {/* 
                  <div
                    className={!templates || !templates.length ? 'quickaction--item quickaction--disabled' : 'quickaction--item'}
                    onClick={async () => {
                      setShowCreateSpreadsheetModal('in-app');
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: '50%',
                        transform: 'translate3d(-50%,-12px,0)',
                      }}
                    >
                      <Label text="Experimental" color={Label.colors.PRIMARY} isAnimationDisabled={true} className="customLabel" />
                    </div>
                    <Flex gap={16} justify={Flex.justify?.CENTER as any} direction={Flex.directions?.COLUMN as any}>
                      <Avatar
                        type={Avatar.types?.ICON as any}
                        size={Avatar.sizes?.MEDIUM as any}
                        withoutBorder={true}
                        icon={MondayLogoOutlineIcon}
                        customBackgroundColor={!templates || !templates.length ? '#d0d4e4' : '#0073ea'}
                        square
                      />
                      <div>
                        <Heading
                          type={Heading.types?.h4 as any}
                          customColor={!templates || !templates.length ? '#d0d4e4' : '#0073ea'}
                          value="Save spreadsheet"
                        />
                        {!templates || !templates.length ? (
                          <div style={{ color: '#e4e5eb' }}>directly to monday.com</div>
                        ) : (
                          <div style={{ color: '#afb0bd' }}>directly to monday.com</div>
                        )}
                      </div>
                    </Flex>
                  </div>
                  */}
                </div>
              </div>
            </div>

            {/* WHAT'S NEW? */}
            {/* QUICK LINKS */}

            <div
              style={{
                display: isMobile ? 'none' : 'block',
                width: '50%',
              }}
            >
              <div style={{ marginLeft: '16px', height: '390px' }}>
                <div
                  style={{
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    gap: '0px',
                    height: '100%',
                    border: '1px solid #d0d4e4',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'stretch',
                      //width: '50%',
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        overflow: 'hidden',
                        position: 'relative',
                        padding: '32px',
                        width: '100%',
                        backgroundColor: '#f6f7fb',
                        //boxShadow: '0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
                        //border: '1px solid #d0d4e4',
                        //borderRadius: '8px',
                        color: '#323338',
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          right: '5%',
                          bottom: '-50px',
                          color: 'rgba(0,0,0,0.03)',
                        }}
                      >
                        <Icon icon={AcademyIcon} iconSize={150} ignoreFocusStyle clickable={false} />
                      </div>
                      <Flex gap={20} justify={Flex.justify?.CENTER} align={Flex.align?.END}>
                        <div
                          style={{
                            width: '100%',
                          }}
                        >
                          <Flex gap={15}>
                            <div>
                              <Flex gap={5}>
                                <Icon icon={AcademyIcon} iconSize={18} ignoreFocusStyle clickable={false} />
                                <span>Guide</span>
                              </Flex>
                              <h5 style={{ marginTop: '10px' }}>
                                Getting started with <br />
                                Spreadsheet Gorilla
                              </h5>
                            </div>
                          </Flex>
                        </div>
                        <div
                          style={{
                            position: 'absolute',
                            bottom: '32px',
                            left: '32px',
                          }}
                        >
                          <IconButton
                            icon={ExternalPage}
                            size={Button.sizes?.SMALL as any}
                            kind={Button.kinds?.SECONDARY as any}
                            color={Button.colors?.PRIMARY as any}
                            onClick={() => {
                              createSignal('get_started_blog');
                              window.open(
                                'https://getgorilla.app/blog/why-should-i-make-use-of-spreadsheet-gorilla-for-monday-com',
                                '_blank'
                              );
                            }}
                          />
                        </div>
                      </Flex>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'stretch',
                      //width: '50%',
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        overflow: 'hidden',
                        position: 'relative',
                        padding: '32px',
                        width: '100%',
                        backgroundColor: '#f6f7fb',
                        borderTop: '1px solid #d0d4e4',
                        //boxShadow: '0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
                        //border: '1px solid #d0d4e4',
                        //borderRadius: '8px',
                        color: '#323338',
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          right: '5%',
                          bottom: '-50px',
                          color: 'rgba(0,0,0,0.03)',
                        }}
                      >
                        <Icon icon={PlayIcon} iconSize={150} ignoreFocusStyle clickable={false} />
                      </div>
                      <Flex gap={20} justify={Flex.justify?.CENTER} align={Flex.align?.END}>
                        <div
                          style={{
                            width: '100%',
                          }}
                        >
                          <Flex gap={15}>
                            <div>
                              <Flex gap={5}>
                                <Icon icon={PlayIcon} iconSize={18} ignoreFocusStyle clickable={false} />
                                <span>Tutorials</span>
                              </Flex>
                              <h5 style={{ marginTop: '10px' }}>
                                Watch all of our video <br />
                                tutorials on YouTube
                              </h5>
                            </div>
                          </Flex>
                        </div>
                        <div
                          style={{
                            position: 'absolute',
                            bottom: '32px',
                            left: '32px',
                          }}
                        >
                          <IconButton
                            icon={ExternalPage}
                            size={Button.sizes?.SMALL as any}
                            kind={Button.kinds?.SECONDARY as any}
                            color={Button.colors?.PRIMARY as any}
                            onClick={() => {
                              createSignal('tutorials');
                              window.open('https://www.youtube.com/playlist?list=PLNt6YgM15GuHMoUhBM0fH1Ji_ARxdiQ2p', '_blank');
                            }}
                          />
                        </div>
                      </Flex>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'stretch',
                      //width: '50%',
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        overflow: 'hidden',
                        position: 'relative',
                        padding: '32px',
                        width: '100%',
                        backgroundColor: '#f6f7fb',
                        borderLeft: '1px solid #d0d4e4',
                        //boxShadow: '0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
                        //border: '1px solid #d0d4e4',
                        //borderRadius: '8px',
                        color: '#323338',
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          right: '5%',
                          bottom: '-50px',
                          color: 'rgba(0,0,0,0.03)',
                        }}
                      >
                        <Icon icon={BoltIcon} iconSize={150} ignoreFocusStyle clickable={false} />
                      </div>
                      <Flex gap={20} justify={Flex.justify?.CENTER} align={Flex.align?.END}>
                        <div
                          style={{
                            width: '100%',
                          }}
                        >
                          <Flex gap={15}>
                            <div>
                              <Flex gap={5}>
                                <Icon icon={BoltIcon} iconSize={18} ignoreFocusStyle clickable={false} />
                                <span>Changelog</span>
                              </Flex>
                              <h5 style={{ marginTop: '10px' }}>
                                Learn more on recent <br />
                                updates and bug fixes
                              </h5>
                            </div>
                          </Flex>
                        </div>
                        <div
                          style={{
                            position: 'absolute',
                            bottom: '32px',
                            left: '32px',
                          }}
                        >
                          <IconButton
                            icon={ExternalPage}
                            size={Button.sizes?.SMALL as any}
                            kind={Button.kinds?.SECONDARY as any}
                            color={Button.colors?.PRIMARY as any}
                            onClick={() => {
                              createSignal('changelog');
                              window.open('https://getgorilla.app/products/spreadsheet-gorilla/changelog', '_blank');
                            }}
                          />
                        </div>
                      </Flex>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'stretch',
                      //width: '50%',
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        overflow: 'hidden',
                        position: 'relative',
                        padding: '32px',
                        width: '100%',
                        backgroundColor: '#f6f7fb',
                        borderTop: '1px solid #d0d4e4',
                        borderLeft: '1px solid #d0d4e4',
                        //boxShadow: '0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
                        //border: '1px solid #d0d4e4',
                        //borderRadius: '8px',
                        color: '#323338',
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          right: '5%',
                          bottom: '-50px',
                          color: 'rgba(0,0,0,0.03)',
                        }}
                      >
                        <Icon icon={CalendarIcon} iconSize={150} ignoreFocusStyle clickable={false} />
                      </div>
                      <Flex gap={20} justify={Flex.justify?.CENTER} align={Flex.align?.END}>
                        <div
                          style={{
                            width: '100%',
                          }}
                        >
                          <Flex gap={15}>
                            <div>
                              <Flex gap={5}>
                                <Icon icon={CalendarIcon} iconSize={18} ignoreFocusStyle clickable={false} />
                                <span>Demo</span>
                              </Flex>
                              <h5 style={{ marginTop: '10px' }}>
                                Book a demo and <br />
                                talk to an expert
                              </h5>
                            </div>
                          </Flex>
                        </div>
                        <div
                          style={{
                            position: 'absolute',
                            bottom: '32px',
                            left: '32px',
                          }}
                        >
                          <IconButton
                            icon={ExternalPage}
                            size={Button.sizes?.SMALL as any}
                            kind={Button.kinds?.SECONDARY as any}
                            color={Button.colors?.PRIMARY as any}
                            onClick={() => {
                              createSignal('book-a-demo');
                              window.open('https://cal.com/gorilla-apps/spreadsheet-gorilla-demo', '_blank');
                            }}
                          />
                        </div>
                      </Flex>
                    </div>
                  </div>
                </div>
              </div>

              {/* 
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '16px',
                  height: '392px',
                  backgroundColor: '#00ca72',
                  boxShadow: '0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
                  borderRadius: '8px',
                  color: '#ffffff',
                }}
                className="dashboard--news"
              >
                <div
                  style={{
                    padding: '48px 0 32px 64px',
                    height: 'auto',
                  }}
                >
                  <Flex align={Flex.align?.CENTER as any}>
                    <Flex align={Flex.align?.CENTER as any}>
                      <div style={{ width: '45%' }}>
                        <h2 style={{ whiteSpace: 'nowrap' }}>What's new?</h2>
                        <p
                          style={{
                            marginBottom: '30px',
                            maxWidth: '310px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            overflowWrap: 'anywhere',
                            display: '-webkit-box',
                            lineClamp: 6,
                            boxOrient: 'vertical',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 6,
                          }}
                        >
                          We're introducing Spreadsheet Gorilla <strong>Automations</strong> with the latest update. Export your templates
                          fully automated and send Excel files by email, or reports by notifications &ndash; enhanced with advanced
                          formulas.
                        </p>
                        <Button
                          leftIcon={ExternalPage}
                          size={Button.sizes?.MEDIUM as any}
                          kind={Button.kinds?.PRIMARY as any}
                          color={Button.colors?.ON_PRIMARY_COLOR as any}
                          onClick={() => {
                            window.open('https://getgorilla.app/products/spreadsheet-gorilla/automations', '_blank');
                          }}
                        >
                          Learn more
                        </Button>
                      </div>
                      <div style={{ pointerEvents: 'none', marginLeft: 'auto', width: '50%', lineHeight: 0 }}>
                        <img src="/news-automations2.png" width="1248" height="926" alt="Export with automations" />
                      </div>
                    </Flex>
                  </Flex>
                </div>
              </div>
              */}
            </div>
          </Flex>
        </div>

        <div
          style={{
            margin: '32px',
          }}
        >
          <Flex gap={32} align={Flex.align?.STRETCH} direction={Flex.directions?.COLUMN}>
            {/* TEMPLATES */}

            <div
              style={{
                width: '100%',
              }}
            >
              <Box
                className="list--wrapper_"
                shadow={Box.shadows?.XS as any}
                rounded={Box.roundeds?.MEDIUM as any}
                border={Box.borders?.DEFAULT as any}
                borderColor={Box.borderColors?.LAYOUT_BORDER_COLOR as any}
              >
                <Box padding={Box.paddings?.LARGE as any} backgroundColor={Box.backgroundColors?.GREY_BACKGROUND_COLOR as any}>
                  <Flex gap={Flex.gaps?.MEDIUM} align={Flex.align?.CENTER} direction={Flex.directions?.ROW}>
                    <Box>
                      <Flex gap={10}>
                        <IconButton
                          icon={showTemplates ? NavigationChevronUpIcon : NavigationChevronDownIcon}
                          size={Button.sizes?.MEDIUM as any}
                          kind={Button.kinds?.TERTIARY as any}
                          onClick={() => {
                            {
                              showTemplates === false ? setShowTemplates(true) : setShowTemplates(false);
                            }
                          }}
                        />
                        <Flex gap={8}>
                          <Heading type={Heading.types?.h4 as any} value="Spreadsheet templates" />
                          <Counter count={templates.length} color={Counter.colors?.DARK} counterClassName="inlinecounter" />
                        </Flex>
                      </Flex>
                    </Box>
                    <Box marginStart={Box.marginStarts?.AUTO as any}>
                      <Flex gap={Flex.gaps?.SMALL} align={Flex.align?.CENTER} direction={Flex.directions?.ROW}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            minHeight: '40px',
                          }}
                        >
                          <SpreadsheetTemplatesWhatsThisDialog />
                        </div>
                      </Flex>
                    </Box>
                  </Flex>
                </Box>

                {showTemplates ? (
                  <>
                    <Divider withoutMargin />
                    <Box className="list--scroll_">
                      {templates && templates.length ? (
                        <>
                          <TemplatesList
                            mondayContext={mondayContext}
                            templates={templates}
                            onDeleteClick={async (template) => {
                              await deleteTemplateTrigger(template);
                              mutateTemplates(templates.filter((t) => t.id !== template.id));
                            }}
                            onDuplicateClick={async (template) => {
                              const newTemplate = await duplicateTemplateTrigger(template);
                              // @ts-ignore
                              navigate(`/template/${newTemplate.id}/edit`);
                            }}
                          />
                        </>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '64px 0',
                            width: '100%',
                            height: '100%',
                            textAlign: 'center',
                          }}
                        >
                          <Flex gap={20} align={Flex.align?.CENTER} justify={Flex.justify?.CENTER} direction={Flex.directions?.COLUMN}>
                            <div>
                              <div
                                style={{
                                  marginBottom: '10px',
                                  color: '#e4e5eb',
                                }}
                              >
                                <Icon icon={LayoutIcon} iconSize={40} ignoreFocusStyle clickable={false} />
                              </div>
                              <Heading type={Heading.types?.h4 as any} value="No templates found" />
                              <p
                                style={{
                                  marginTop: '5px',
                                  marginBottom: '10px',
                                  fontWeight: 300,
                                }}
                              >
                                Let's create a template, shall we?
                              </p>
                            </div>
                            <Button
                              kind={Button.kinds?.PRIMARY as any}
                              loading={isCreatingTemplate}
                              onClick={async () => {
                                const newTemplate = await createTemplateTrigger();
                                mutateTemplates(templates ? [...templates, newTemplate] : [newTemplate]);
                                navigate(`/template/${newTemplate.id}/edit`);
                              }}
                            >
                              Create template
                            </Button>
                          </Flex>
                        </div>
                      )}
                    </Box>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </div>

            {/* SPREADSHEETS */}

            <div
              style={{
                width: '100%',
              }}
            >
              <Box
                className="list--wrapper_"
                shadow={Box.shadows?.XS as any}
                rounded={Box.roundeds?.MEDIUM as any}
                border={Box.borders?.DEFAULT as any}
                borderColor={Box.borderColors?.LAYOUT_BORDER_COLOR as any}
              >
                <Box padding={Box.paddings?.LARGE as any} backgroundColor={Box.backgroundColors?.GREY_BACKGROUND_COLOR as any}>
                  <Flex gap={Flex.gaps?.MEDIUM} align={Flex.align?.CENTER} direction={Flex.directions?.ROW}>
                    <Box>
                      <Flex gap={10}>
                        <IconButton
                          icon={showSpreadsheets ? NavigationChevronUpIcon : NavigationChevronDownIcon}
                          size={Button.sizes?.MEDIUM as any}
                          kind={Button.kinds?.TERTIARY as any}
                          onClick={() => {
                            {
                              showSpreadsheets === false ? setShowSpreadsheets(true) : setShowSpreadsheets(false);
                            }
                          }}
                        />
                        <Flex gap={8}>
                          <Heading type={Heading.types?.h4 as any} value="Saved spreadsheets" />
                          <Counter count={spreadsheets?.length || 0} color={Counter.colors?.DARK} counterClassName="inlinecounter" />
                        </Flex>
                      </Flex>
                    </Box>

                    <Box marginStart={Box.marginStarts?.AUTO as any}>
                      <Flex gap={Flex.gaps?.SMALL} align={Flex.align?.CENTER} direction={Flex.directions?.ROW}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            minHeight: '40px',
                          }}
                        >
                          <SpreadsheetsWhatsThisDialog />
                        </div>
                      </Flex>
                    </Box>
                  </Flex>
                </Box>

                {showSpreadsheets ? (
                  <>
                    <Divider withoutMargin />

                    <Box className="list--scroll_">
                      {spreadsheets && spreadsheets.length ? (
                        <Box>
                          <SpreadsheetsList
                            spreadsheets={spreadsheets}
                            onDeleteClick={async (spreadsheet) => {
                              await deleteSpreadsheetTrigger(spreadsheet as any); // TODO: fix any
                              mutateSpreadsheets(spreadsheets.filter((s) => s.id !== spreadsheet.id));
                              createSignal('spreadsheet_delete');
                            }}
                          />
                        </Box>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '64px 0',
                            width: '100%',
                            height: '100%',
                            textAlign: 'center',
                          }}
                        >
                          <Flex gap={20} align={Flex.align?.CENTER} justify={Flex.justify?.CENTER} direction={Flex.directions?.COLUMN}>
                            <div>
                              <div
                                style={{
                                  marginBottom: '10px',
                                  color: '#e4e5eb',
                                }}
                              >
                                <Icon icon={MondayLogoOutlineIcon} iconSize={40} ignoreFocusStyle clickable={false} />
                              </div>
                              <Heading type={Heading.types?.h4 as any} value="No spreadsheets found" />
                              <p
                                style={{
                                  marginTop: '5px',
                                  marginBottom: '10px',
                                  fontWeight: 300,
                                }}
                              >
                                {templates && templates.length ? (
                                  <>Let's create a spreadsheet, shall we?</>
                                ) : (
                                  <>First create a spreadsheet template.</>
                                )}
                              </p>
                            </div>
                            <Button
                              kind={Button.kinds?.PRIMARY as any}
                              size={Button.sizes?.MEDIUM as any}
                              disabled={!templates || !templates.length}
                              onClick={async () => {
                                setShowCreateSpreadsheetModal(true);
                              }}
                            >
                              Create spreadsheet
                            </Button>
                          </Flex>
                        </div>
                      )}
                    </Box>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </div>
          </Flex>
        </div>
        {/*
        <div
          style={{
            margin: '32px 32px 32px 32px',
          }}
        >
          <Flex gap={32} align={Flex.align?.STRETCH} justify={Flex.justify?.STRETCH} direction={Flex.directions?.ROW} style={{ lineHeight: 0 }}>
            <div
              style={{
                overflow: 'hidden',
                flex: 1,
                backgroundColor: '#f6f7fb',
                boxShadow: '0px 4px 6px -4px rgba(0, 0, 0, 0.11)',
                borderRadius: '8px',
                lineHeight: 0,
              }}              
            >
              <a 
                href="https://www.youtube.com/watch?v=cR5i2PJBjhs"
                target="_blank"
                style={{
                  display: 'block',
                  lineHeight: 0,
                }}
              >
                <img src="/export-and-email.webp" width="1920" height="1080" alt="" />
              </a>
            </div>
            <div
              style={{
                overflow: 'hidden',
                flex: 1,
                backgroundColor: '#f6f7fb',
                boxShadow: '0px 4px 6px -4px rgba(0, 0, 0, 0.11)',
                borderRadius: '8px',
                lineHeight: 0,
              }}              
            >
              <a 
                href="https://www.youtube.com/watch?v=cR5i2PJBjhs"
                target="_blank"
                style={{
                  display: 'block',
                  lineHeight: 0,
                }}
              >
                <img src="/export-and-email.webp" width="1920" height="1080" alt="" />
              </a>
            </div>
          </Flex>
        </div>
/*}

        {/* CTA */}
        {/* 
        <div
          style={{
            margin: '32px 32px 32px 32px',
          }}
        >
          <Flex gap={32} align={Flex.align?.STRETCH} justify={Flex.justify?.STRETCH} direction={Flex.directions?.ROW}>
            <div
              style={{
                display: 'flex',
                alignItems: 'stretch',
                width: '50%',
              }}
            >
              <div
                style={{
                  overflow: 'hidden',
                  position: 'relative',
                  padding: '32px',
                  width: '100%',
                  backgroundColor: 'rgb(0, 115, 234)',
                  boxShadow: '0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
                  borderRadius: '8px',
                  color: '#ffffff',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    right: '20%',
                    bottom: '-50px',
                    color: 'rgba(255,255,255,0.05)',
                  }}
                >
                  <Icon icon={CalendarIcon} iconSize={150} ignoreFocusStyle clickable={false} />
                </div>
                <Flex gap={20} justify={Flex.justify?.CENTER} align={Flex.align?.END}>
                  <div>
                    <Flex gap={15}>
                      <div>
                        <Flex gap={5}>
                          <Icon icon={CalendarIcon} iconSize={18} ignoreFocusStyle clickable={false} />
                          <span>Demo</span>
                        </Flex>
                        <h5 style={{ marginTop: '5px' }}>
                          Book a demo and <br />
                          talk to an expert
                        </h5>
                      </div>
                    </Flex>
                  </div>
                  <div
                    style={{
                      marginLeft: 'auto',
                    }}
                  >
                    <IconButton
                      icon={ExternalPage}
                      size={Button.sizes?.MEDIUM as any}
                      kind={Button.kinds?.SECONDARY as any}
                      color={Button.colors?.ON_PRIMARY_COLOR as any}
                      onClick={() => {
                        createSignal('book_a_demo');
                        window.open('https://cal.com/gorilla-apps/spreadsheet-gorilla-demo', '_blank');
                      }}
                    />
                  </div>
                </Flex>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'stretch',
                width: '50%',
              }}
            >
              <div
                style={{
                  overflow: 'hidden',
                  position: 'relative',
                  padding: '32px',
                  width: '100%',
                  backgroundColor: 'rgb(0, 115, 234)',
                  boxShadow: '0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
                  borderRadius: '8px',
                  color: '#ffffff',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    right: '20%',
                    bottom: '-50px',
                    color: 'rgba(255,255,255,0.05)',
                  }}
                >
                  <Icon icon={FormIcon} iconSize={150} ignoreFocusStyle clickable={false} />
                </div>
                <Flex gap={20} justify={Flex.justify?.CENTER} align={Flex.align?.END}>
                  <div>
                    <Flex gap={15}>
                      <div>
                        <Flex gap={5}>
                          <Icon icon={FormIcon} iconSize={18} ignoreFocusStyle clickable={false} />
                          <span>Support</span>
                        </Flex>
                        <h5 style={{ marginTop: '5px' }}>
                          We're here to help and <br />
                          answer any question
                        </h5>
                      </div>
                    </Flex>
                  </div>
                  <div
                    style={{
                      marginLeft: 'auto',
                    }}
                  >
                    <IconButton
                      icon={ExternalPage}
                      size={Button.sizes?.MEDIUM as any}
                      kind={Button.kinds?.SECONDARY as any}
                      color={Button.colors?.ON_PRIMARY_COLOR as any}
                      onClick={() => {
                        createSignal('support');
                        window.open('https://getgorilla.app/contact', '_blank');
                      }}
                    />
                  </div>
                </Flex>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'stretch',
                width: '50%',
              }}
            >
              <div
                style={{
                  overflow: 'hidden',
                  position: 'relative',
                  padding: '32px',
                  width: '100%',
                  backgroundColor: 'rgb(0, 115, 234)',
                  boxShadow: '0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
                  borderRadius: '8px',
                  color: '#ffffff',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    right: '20%',
                    bottom: '-50px',
                    color: 'rgba(255,255,255,0.05)',
                  }}
                >
                  <Icon icon={FeedbackIcon} iconSize={150} ignoreFocusStyle clickable={false} />
                </div>
                <Flex gap={20} justify={Flex.justify?.CENTER} align={Flex.align?.END}>
                  <div>
                    <Flex gap={15}>
                      <div>
                        <Flex gap={5}>
                          <Icon icon={FeedbackIcon} iconSize={18} ignoreFocusStyle clickable={false} />
                          <span>Feedback</span>
                        </Flex>
                        <h5 style={{ marginTop: '5px' }}>
                          Share your feedback <br />
                          and user experience
                        </h5>
                      </div>
                    </Flex>
                  </div>
                  <div
                    style={{
                      marginLeft: 'auto',
                    }}
                  >
                    <IconButton
                      icon={ExternalPage}
                      size={Button.sizes?.MEDIUM as any}
                      kind={Button.kinds?.SECONDARY as any}
                      color={Button.colors?.ON_PRIMARY_COLOR as any}
                      onClick={() => {
                        createSignal('feedback');
                        window.open('https://wkf.ms/3RMU0MH', '_blank');
                      }}
                    />
                  </div>
                </Flex>
              </div>
            </div>
          </Flex>
        </div>
*/}
      </div>
    </div>
  );
}

export default HomePage;
