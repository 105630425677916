import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import { Avatar, Heading, Box, Button, Flex, Divider, SplitButton, SplitButtonMenu, MenuItem } from 'monday-ui-react-core';
// @ts-ignore
import BoardIcon from 'monday-ui-react-core/dist/icons/Board';
// @ts-ignore
import Locked from 'monday-ui-react-core/dist/icons/Locked';
// @ts-ignore
import LayoutIcon from 'monday-ui-react-core/dist/icons/Layout';
// @ts-ignore
import TurnIntoIcon from 'monday-ui-react-core/dist/icons/TurnInto';
import { useNavigate } from 'react-router-dom';
import { Template } from '@gorilla/spreadsheet-shared/src/lib/spreadsheet-manager/types';
import CreatedBy from '../shared/components/CreatedBy';
import { InstantUpdateModal } from './ToMondayModal';

function getQuickActions(template: Template) {
  const templateConfig = template.config;
  const results: any[] = [];

  if (!templateConfig) {
    return results;
  }

  for (const sheet of templateConfig.sheets) {
    for (const fieldGroup of sheet.field_groups) {
      for (let i = 0; i < fieldGroup.columns.length; i++) {
        const column = fieldGroup.columns[i];
        const pathPiece = column.path[column.path.length - 1];

        // @ts-ignore
        const isCustomFormula = pathPiece?.field_id === 'custom-formula';

        if (
          isCustomFormula &&
          column.config?.to_monday_target_column_id &&
          column.config?.to_monday_is_quick_actions &&
          column.config?.to_monday_quick_action_name
        ) {
          let initialCtx: any = {};

          if (column.config?.to_monday_target_column_id) {
            initialCtx.targetColumnId = column.config.to_monday_target_column_id;
          }

          if (_.isBoolean(column.config?.to_monday_is_quick_actions)) {
            initialCtx.isQuickAction = column.config.to_monday_is_quick_actions;
          }

          if (column.config?.to_monday_quick_action_name) {
            initialCtx.quickActionName = column.config.to_monday_quick_action_name;
          }

          results.push({
            template: template,
            column,
            fieldGroup,
            initialCtx,
            formulaColumnIdx: i,
            sheet,
            label: column.config?.to_monday_quick_action_name,
          });
        }
      }
    }
  }

  return results;
}

type TemplatesListProps = {
  mondayContext: { boardId: number };
  templates: any[];
  onDeleteClick: (template: any) => void;
  onDuplicateClick: (template: any) => void;
};

export function TemplatesList({ templates, onDeleteClick, onDuplicateClick, mondayContext }: TemplatesListProps) {
  const [quickActionModal, setQuickActionModal] = useState<any>();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {quickActionModal ? (
        <InstantUpdateModal
          onClose={() => {
            setQuickActionModal(null);
          }}
          show={true}
          initialCtx={quickActionModal.initialCtx}
          formulaColumnIdx={quickActionModal.formulaColumnIdx}
          template={quickActionModal.template}
          fieldGroup={quickActionModal.fieldGroup}
          sheet={quickActionModal.sheet}
        />
      ) : null}
      <Box>
        {_.orderBy(templates, 'name').map((template, i) => {
          const quickActions = getQuickActions(template);

          return (
            <div key={template.id}>
              <Box>
                <Box padding={Box.paddings?.LARGE as any} paddingEnd={Box.paddingEnds?.XL as any} className="list--element">
                  <Flex gap={Flex.gaps?.SMALL} align={Flex.align?.CENTER} direction={Flex.directions?.ROW}>
                    <Avatar
                      type={Avatar.types?.ICON as any}
                      size={Avatar.sizes?.MEDIUM as any}
                      withoutBorder={true}
                      icon={LayoutIcon}
                      customBackgroundColor="#0073ea"
                      square
                    />

                    <Flex gap={Flex.gaps?.LARGE} align={Flex.align?.CENTER} direction={Flex.directions?.ROW}>
                      <Box paddingEnd={Box.paddingEnds?.XS as any}>
                        <Flex gap={Flex.gaps?.XS} style={{ maxWidth: '300px' }}>
                          <Heading type={Heading.types?.h5 as any} value={template.name} ellipsis />
                          <>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'nowrap',
                                gap: '8px',
                                height: '28px',
                              }}
                            >
                              {(template.boards || []).some((boardId) => boardId === mondayContext.boardId) ? (
                                <>
                                  <Avatar
                                    withoutBorder
                                    size={Avatar.sizes?.SMALL}
                                    type={Avatar.types?.ICON}
                                    icon={BoardIcon}
                                    customBackgroundColor="#00ca72"
                                    ariaLabel="This board is used in this template"
                                    className="avatar-indicator"
                                  />
                                </>
                              ) : null}

                              {template.private ? (
                                <>
                                  <Avatar
                                    withoutBorder
                                    size={Avatar.sizes?.SMALL}
                                    type={Avatar.types?.ICON}
                                    icon={Locked}
                                    customBackgroundColor="#ffb900"
                                    ariaLabel="Private template is hidden to others"
                                    className="avatar-indicator"
                                  />
                                </>
                              ) : null}
                            </div>
                          </>
                        </Flex>
                        <div style={{ marginTop: '-3px' }}>
                          <CreatedBy userId={template.creatorId} time={template.createdAt} userAvatar={false} />
                        </div>
                      </Box>
                    </Flex>

                    {quickActions.length > 0 ? (
                      quickActions.length < 4 && !isMobile ? (
                        <Flex gap={8} style={{ marginLeft: '20px' }}>
                          {quickActions.map((quickAction, idx) => {
                            return (
                              <Button
                                key={idx}
                                leftIcon={TurnIntoIcon}
                                size={Button.sizes?.SMALL as any}
                                kind={Button.kinds?.SECONDARY as any}
                                onClick={() => {
                                  setQuickActionModal(quickAction);
                                }}
                              >
                                {quickAction.label}
                              </Button>
                            );
                          })}
                        </Flex>
                      ) : (
                        <div style={{ marginLeft: '20px' }}>
                          <SplitButton
                            leftIcon={TurnIntoIcon}
                            size={SplitButton.sizes?.SMALL as any}
                            kind={SplitButton.kinds?.SECONDARY as any}
                            secondaryDialogContent={
                              <SplitButtonMenu id="split-menu-actions">
                                {quickActions.map((quickAction, index) => {
                                  return index !== 0 ? (
                                    <MenuItem
                                      icon={TurnIntoIcon}
                                      title={quickAction.label}
                                      onClick={() => {
                                        setQuickActionModal(quickAction);
                                      }}
                                    />
                                  ) : null;
                                })}
                              </SplitButtonMenu>
                            }
                            onClick={() => {
                              setQuickActionModal(quickActions[0]);
                            }}
                          >
                            {quickActions[0].label}
                          </SplitButton>
                        </div>
                      )
                    ) : null}

                    <Box marginStart={Box.marginStarts?.AUTO as any}>
                      <Flex gap={0} align={Flex.align?.CENTER} direction={Flex.directions?.ROW}>
                        <Button
                          size={Button.sizes?.SMALL as any}
                          kind={Button.kinds?.TERTIARY as any}
                          onClick={() => {
                            navigate(`/template/${template.id}/render`);
                          }}
                        >
                          Preview
                        </Button>
                        <Flex gap={Flex.gaps?.SMALL} align={Flex.align?.CENTER} direction={Flex.directions?.ROW}>
                          <Button
                            //leftIcon={DuplicateIcon}
                            size={Button.sizes?.SMALL as any}
                            kind={Button.kinds?.TERTIARY as any}
                            onClick={() => {
                              onDuplicateClick(template);
                            }}
                          >
                            Duplicate
                          </Button>
                          <Button
                            size={Button.sizes?.SMALL as any}
                            kind={Button.kinds?.PRIMARY as any}
                            onClick={() => {
                              navigate(`/template/${template.id}/edit`);
                            }}
                          >
                            Edit
                          </Button>
                        </Flex>
                      </Flex>
                    </Box>
                  </Flex>
                </Box>
              </Box>
              {/* {templates.length > 3 && templates.length === i + 1 ? null : <Divider withoutMargin={true} />} */}
              {templates.length === i + 1 ? null : <Divider withoutMargin={true} />}
            </div>
          );
        })}
      </Box>
    </>
  );
}
