import React from 'react';
import { useState } from 'react';
import * as _ from 'lodash';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Button,
  Heading,
  Box,
  Flex,
  Icon,
  Tooltip,
  IconButton,
  Checkbox,
  Dropdown,
  TextField,
  Avatar,
  Badge,
  SplitButton,
  Label,
  Dialog,
  DialogContentContainer,
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from 'monday-ui-react-core';
// @ts-ignore-next-line
import TableIcon from 'monday-ui-react-core/dist/icons/Table';
// @ts-ignore-next-line
import MenuIcon from 'monday-ui-react-core/dist/icons/Menu';
// @ts-ignore-next-line
import DragIcon from 'monday-ui-react-core/dist/icons/Drag';
// @ts-ignore-next-line
import MoveArrowRightIcon from 'monday-ui-react-core/dist/icons/MoveArrowRight';
// @ts-ignore-next-line
import DeleteIcon from 'monday-ui-react-core/dist/icons/Delete';
// @ts-ignore-next-line
import PageIcon from 'monday-ui-react-core/dist/icons/Page';
// @ts-ignore-next-line
import ColumnIcon from 'monday-ui-react-core/dist/icons/Column';
// @ts-ignore-next-line
import SettingsIcon from 'monday-ui-react-core/dist/icons/Settings';
// @ts-ignore-next-line
import EducationIcon from 'monday-ui-react-core/dist/icons/Education';
// @ts-ignore-next-line
import FilterIcon from 'monday-ui-react-core/dist/icons/Filter';
// @ts-ignore-next-line
import BoardIcon from 'monday-ui-react-core/dist/icons/Board';
// @ts-ignore-next-line
import TurnIntoIcon from 'monday-ui-react-core/dist/icons/TurnInto';
// @ts-ignore-next-line
import StatusIcon from 'monday-ui-react-core/dist/icons/Status';
// @ts-ignore-next-line
import FormulaIcon from 'monday-ui-react-core/dist/icons/Formula';
import {
  getBoardFieldId,
  getBoardFields,
  isBlankBoardField,
  isCustomFormulaBoardField,
} from '@gorilla/spreadsheet-shared/src/lib/spreadsheet-manager/spreadsheet-manager';
import {
  BoardField,
  Template,
  TemplateConfig,
  BoardColumn,
  FieldGroup as FieldGroupType,
  Sheet,
} from '@gorilla/spreadsheet-shared/src/lib/spreadsheet-manager/types';
import { BoardRulesModal } from '@gorilla/ui/src/components/BoardRulesModal';
import { Board } from '@gorilla/common/src/lib/monday-api/api';
import { hasBoardFilters, areBoardFiltersValid } from '@gorilla/common/src/lib/rules';
import { FieldPicker } from './FieldPicker';
import { ColumnConfigModal } from './ColumnConfigModal';
import { createRandomId, indexToAlpha } from '../lib/utils';
import { FormulaDocumentationModal } from './FormulaDocumentationModal';
import { ToMondayModal } from '../components/ToMondayModal';
import { createSignal } from '../services/signals';

// @ts-ignore
const S5SCalc = window.S5SCalc as any;

function isValidFormula(formula: string) {
  if (!formula.length || formula[0] !== '=') {
    return true;
  }

  try {
    // remove first char of formula
    const formulaPrepared = (formula.substring(1) as any).replaceAll('$$', 1);
    const result = S5SCalc.calculate(formulaPrepared);

    return true;
  } catch (err) {
    if (err.message?.startsWith('Cannot read properties of undefined')) {
      return true;
    } else {
      return false;
    }
  }
}

const COLUMN_TYPE_OPTIONS = [
  {
    label: 'Board column',
    value: 'board-column',
    leftIcon: ColumnIcon,
  },
  {
    label: 'Formula',
    value: 'custom-formula',
    leftIcon: FormulaIcon,
  },
  {
    label: 'Blank',
    value: 'blank',
    leftIcon: PageIcon,
  },
];

type ColumnProps = {
  idx: number;
  fieldGroup: FieldGroupType;
  board: Board;
  template: Template;
  sheet: Sheet;

  fields: BoardField[];
  column: BoardColumn;
  onChange: (column: BoardColumn) => void;
  onRemove: () => void;
  onSave: () => void;
};

function Column({ idx, fields, column, onChange, onRemove, fieldGroup, board, template, sheet, onSave }: ColumnProps) {
  const [showDocumentation, setShowDocumentation] = useState(false);
  const [showConfig, setShowConfig] = useState(false);
  const [showToMondayModal, setShowToMondayModal] = useState<boolean>(false);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: column.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const boardColumnFields = fields.filter((f) => !isBlankBoardField(f) && !isCustomFormulaBoardField(f));

  const boardFieldId = getBoardFieldId(column);
  const boardField = fields.find(({ id }) => id === boardFieldId);

  let customFormulaValidation: any = null;
  let selectedColumnTypeId = 'board-column';

  if (boardField && isBlankBoardField(boardField)) {
    selectedColumnTypeId = 'blank';
  } else if (boardField && isCustomFormulaBoardField(boardField)) {
    selectedColumnTypeId = 'custom-formula';

    if (!isValidFormula(column.config?.formula || '')) {
      customFormulaValidation = {
        status: 'error',
      };
    }
  }

  const selectedColumnTypeOption = COLUMN_TYPE_OPTIONS.find((option) => option.value === selectedColumnTypeId);
  let initialCtx: any = {};

  if (column.config?.to_monday_target_column_id) {
    initialCtx.targetColumnId = column.config.to_monday_target_column_id;
  }

  if (_.isBoolean(column.config?.to_monday_is_quick_actions)) {
    initialCtx.isQuickAction = column.config.to_monday_is_quick_actions;
  }

  if (column.config?.to_monday_quick_action_name) {
    initialCtx.quickActionName = column.config.to_monday_quick_action_name;
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      {showToMondayModal ? (
        <ToMondayModal
          onClose={() => setShowToMondayModal(false)}
          show={true}
          initialCtx={initialCtx}
          targetBoard={board}
          template={template}
          formulaColumnIdx={idx}
          fieldGroup={fieldGroup}
          sheet={sheet}
          formula={column.config?.formula || ''}
          onSaveSettings={(toMondaySettings) => {
            const newConfig = column.config ? { ...column.config, ...toMondaySettings } : toMondaySettings;

            onChange({
              ...column,
              config: newConfig,
            });

            setTimeout(() => {
              onSave();
            }, 10);
          }}
        />
      ) : null}
      <div style={{ overflow: 'visible', marginBottom: '8px' }}>
        <Flex gap={Flex.gaps?.LARGE} align={Flex.align?.CENTER} direction={Flex.directions?.ROW}>
          <div
            className="dragger"
            {...listeners}
            style={{
              lineHeight: 0,
            }}
          >
            <Icon icon={DragIcon} iconSize={20} />
          </div>

          <div
            style={{
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'inline-flex',
                flexWrap: 'nowrap',
                gap: '7px',
                width: '100%',
              }}
            >
              <div style={{ width: '170px' }}>
                <Dropdown
                  onOptionSelect={(ev: any) => {
                    if (ev.value === 'blank') {
                      onChange({
                        ...column,
                        path: [{ type: 'field', board_id: fieldGroup.board_id, field_id: 'blank' }],
                        config: null,
                      });
                    } else if (ev.value === 'custom-formula') {
                      onChange({
                        ...column,
                        path: [{ type: 'field', board_id: fieldGroup.board_id, field_id: 'custom-formula' }],
                        config: null,
                      });
                    } else {
                      onChange({
                        ...column,
                        path: boardColumnFields[0].path,
                        config: null,
                      });
                    }
                  }}
                  value={selectedColumnTypeOption}
                  options={COLUMN_TYPE_OPTIONS}
                  placeholder="Select type"
                  insideOverflowContainer
                  clearable={false}
                  className="column-type-selector"
                  dropdownMenuWrapperClassName="dropdown_content_indexed"
                />
              </div>
              {/* 
                {selectedColumnTypeId === 'board-column' && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip content="Tooltip" position={Tooltip.positions.RIGHT}>
                    <Avatar
                      type={Avatar.types?.ICON as any}
                      size={Avatar.sizes?.MEDIUM as any}
                      withoutBorder={true}
                      icon={boardColumnType()}
                      square
                      className="avatar-indicator avatar-indicator--warning"
                    />
                    </Tooltip>
                </div>
                )}
                */}
              <div style={{ flex: 1 }}>
                {selectedColumnTypeId === 'board-column' && (
                  <FieldPicker
                    fields={boardColumnFields}
                    value={getBoardFieldId(column)}
                    onChange={(field) => {
                      onChange({
                        ...column,
                        path: field.path,
                        config: null,
                      });
                    }}
                  />
                )}
                {selectedColumnTypeId === 'custom-formula' && (
                  <Flex gap={8}>
                    <TextField
                      size={TextField.sizes?.MEDIUM}
                      iconName={EducationIcon}
                      className="formula--input"
                      placeholder="=SUM(A2:A10)"
                      onIconClick={() => {
                        setShowDocumentation(true);
                      }}
                      onChange={(newFormula) => {
                        onChange({
                          ...column,
                          config: {
                            ...(column?.config || {}),
                            formula: newFormula,
                          },
                        });
                      }}
                      value={column.config?.formula || ''}
                      validation={customFormulaValidation}
                    />
                    <Tooltip content="Update board items" position={Tooltip.positions.RIGHT}>
                      <div style={{ backgroundColor: '#ffffff' }}>
                        {column.config?.to_monday_is_quick_actions ? (
                          <Badge color={'primary' as any}>
                            <IconButton
                              icon={TurnIntoIcon}
                              size={IconButton.sizes?.MEDIUM as any}
                              kind={IconButton.kinds?.PRIMARY as any}
                              onClick={async () => {
                                onSave();
                                createSignal('to_monday_open_modal');
                                setShowToMondayModal(true);
                              }}
                            />
                          </Badge>
                        ) : (
                          <IconButton
                            icon={TurnIntoIcon}
                            size={IconButton.sizes?.MEDIUM as any}
                            kind={IconButton.kinds?.PRIMARY as any}
                            onClick={async () => {
                              onSave();
                              createSignal('to_monday_open_modal');
                              setShowToMondayModal(true);
                            }}
                          />
                        )}
                      </div>
                    </Tooltip>
                  </Flex>
                )}
                {selectedColumnTypeId === 'blank' && (
                  <div
                    style={{
                      flex: 1,
                      height: '38px',
                      minHeight: '30px',
                      backgroundColor: 'transparent',
                      border: '1px dotted #c3c6d4',
                      borderRadius: '4px',
                    }}
                  >
                    &nbsp;
                  </div>
                )}
              </div>
            </div>
          </div>

          {showDocumentation ? (
            <FormulaDocumentationModal
              show={showDocumentation}
              onClose={() => {
                setShowDocumentation(false);
              }}
            />
          ) : (
            <></>
          )}

          <div
            style={{
              pointerEvents: 'none',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Icon icon={MoveArrowRightIcon} iconSize={20} />
          </div>

          {column.config?.cell_bg_color ||
          column.config?.cell_font_bold ||
          column.config?.cell_font_cancelline ||
          column.config?.cell_font_color ||
          column.config?.cell_font_italic ||
          column.config?.cell_font_size ||
          column.config?.cell_font_type ||
          column.config?.cell_font_underline ||
          column.config?.cell_horizontal_alignment ? (
            <Badge color={'primary' as any}>
              <div
                className="column--box"
                style={{
                  width: '160px',
                  minWidth: '160px',
                  maxWidth: '160px',
                  borderColor: '#0073ea',
                }}
                onClick={() => {
                  setShowConfig(true);
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 4px 0 12px',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <div
                      style={{
                        pointerEvents: 'none',
                        transform: 'rotate(90deg)',
                      }}
                    >
                      <Icon icon={StatusIcon} iconSize={16} />
                    </div>
                    <div>Column {indexToAlpha(idx)}</div>
                  </div>
                  <div
                    style={{
                      margin: '4px 3px 0px auto',
                    }}
                  >
                    <IconButton
                      icon={SettingsIcon}
                      kind={IconButton.kinds?.TERTIARY as any}
                      size={IconButton.sizes?.XS as any}
                      onClick={() => {
                        setShowConfig(true);
                      }}
                      className="icon--button icon--button-grey"
                    />
                  </div>
                </div>
              </div>
            </Badge>
          ) : (
            <div
              className="column--box"
              style={{
                width: '160px',
                minWidth: '160px',
                maxWidth: '160px',
              }}
              onClick={() => {
                setShowConfig(true);
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0 4px 0 12px',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <div
                    style={{
                      pointerEvents: 'none',
                      transform: 'rotate(90deg)',
                    }}
                  >
                    <Icon icon={StatusIcon} iconSize={16} />
                  </div>
                  <div>Column {indexToAlpha(idx)}</div>
                </div>
                <div
                  style={{
                    margin: '4px 3px 0px auto',
                  }}
                >
                  <IconButton
                    icon={SettingsIcon}
                    kind={IconButton.kinds?.TERTIARY as any}
                    size={IconButton.sizes?.XS as any}
                    onClick={() => {
                      setShowConfig(true);
                    }}
                    className="icon--button icon--button-grey"
                  />
                </div>
              </div>
            </div>
          )}

          {showConfig && boardField ? (
            <ColumnConfigModal
              config={column.config || null}
              show={showConfig}
              columnName={indexToAlpha(idx)}
              fieldName={boardField ? boardField.label : ''}
              field={boardField}
              onClose={(newConfig) => {
                if (newConfig) {
                  onChange({
                    ...column,
                    config: newConfig,
                  });
                }
                setShowConfig(false);
              }}
            />
          ) : (
            <></>
          )}

          <div>
            <Flex gap={Flex.gaps?.XS} align={Flex.align?.CENTER} direction={Flex.directions?.ROW}>
              <IconButton
                icon={DeleteIcon}
                kind={IconButton.kinds?.TERTIARY as any}
                size={IconButton.sizes?.SMALL as any}
                onClick={() => {
                  onRemove();
                }}
                className="icon--button-small"
              />
            </Flex>
          </div>
        </Flex>
      </div>
    </div>
  );
}

type FieldGroupProps = {
  boards: Board[];

  config: TemplateConfig;

  fieldGroup: FieldGroupType;

  template: Template;

  sheet: Sheet;

  onChange: (fieldGroup: FieldGroupType) => void;

  onRemove: () => void;

  onSave: () => void;
};

export function FieldGroup({ boards, fieldGroup, template, onRemove, onChange, sheet, onSave }: FieldGroupProps) {
  const [showFilters, setShowFilters] = useState(false);
  const [moreButtonActive, setMoreButtonActive] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showOptionExplanationModal, setShowOptionExplanationModal] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const board = boards.find((b) => fieldGroup.board_id === b.id);
  const fields = board ? getBoardFields(board) : [];
  const boardColumnFields = fields.filter((f) => !isBlankBoardField(f) && !isCustomFormulaBoardField(f));

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = fieldGroup.columns.findIndex(({ id }) => id === active.id);
      const newIndex = fieldGroup.columns.findIndex(({ id }) => id === over.id);

      onChange({
        ...fieldGroup,
        columns: arrayMove(fieldGroup.columns, oldIndex, newIndex),
      });
    }
  }

  return (
    <>
      {showFilters ? (
        <BoardRulesModal
          isOpen={showFilters}
          board={board!}
          inititalBoardFilters={fieldGroup.board_filters || {}}
          onClose={(newBoardFilters) => {
            if (newBoardFilters !== null && newBoardFilters !== undefined) {
              onChange({
                ...fieldGroup,
                board_filters: newBoardFilters,
              });
            }

            setShowFilters(false);
          }}
        />
      ) : null}

      <Modal
        data-testid="option-explanation"
        show={showOptionExplanationModal}
        onClose={() => {
          setShowOptionExplanationModal(false);
        }}
        width={500 as any}
        alertDialog={false}
      >
        <ModalHeader title=" " closeButtonAriaLabel="" hideCloseButton />
        <ModalContent>
          <Box>
            <div style={{ marginBottom: '15px' }}>
              <Flex gap={12}>
                <h2>What are Dynamic Boards?</h2>
              </Flex>
              <p>
                If you have multiple boards with the same column structure, you typically need separate Spreadsheet Templates for each. To
                simplify, mark a board as a "Dynamic Board" in your Spreadsheet Template. During export, all board columns are then replaced
                with the current board's data.
              </p>
              <p>
                For example, if boards A and B share the same structure, you can create a Spreadsheet Template with board A marked as a
                "Dynamic board". When you set up an export automation in board B using this template, it will export the items from board B
                instead of board A.
              </p>
            </div>
          </Box>
        </ModalContent>
        <ModalFooter>
          <Flex gap={12} justify={Flex.justify?.END as any}>
            <Button
              kind={Button.kinds?.TERTIARY as any}
              onClick={() => {
                setShowOptionExplanationModal(false);
              }}
            >
              Close
            </Button>
            <Button
              color={Button.colors.PRIMARY}
              onClick={async () => {
                //setShowOptionExplanationModal(false);
                window.open('https://getgorilla.app/products/spreadsheet-gorilla/dynamic-boards', '_blank');
              }}
            >
              Learn more
            </Button>
          </Flex>
        </ModalFooter>
      </Modal>

      <div id={`${fieldGroup.id}`}>
        <div>
          <div
            style={{
              position: 'relative',
            }}
          >
            <div
              style={{
                zIndex: 100,
                position: 'sticky',
                top: '73px',
                padding: '32px 32px 22px 32px',
                backgroundColor: '#f6f7fb',
                borderTopRightRadius: '8px',
              }}
            >
              <Flex>
                <div style={{ width: 'auto' }}>
                  <Flex align={Flex.align?.CENTER as any} gap={30}>
                    <Flex align={Flex.align?.CENTER as any} gap={10}>
                      <Avatar
                        withoutBorder
                        square
                        size={Avatar.sizes?.MEDIUM}
                        type={Avatar.types?.ICON}
                        icon={BoardIcon}
                        customBackgroundColor="#323338"
                      />
                      <Flex align={Flex.align?.CENTER as any} gap={10}>
                        <Heading type={Heading.types?.h4 as any} value={board?.name || `Board: ${fieldGroup.board_id}`} ellipsis={false} />
                        {fieldGroup.disable ? (
                          <Tooltip content="Board will be skipped during export" position={Tooltip.positions.RIGHT} zIndex={2000}>
                            <Label text="Disabled Board" color={Label.colors.NEGATIVE} isAnimationDisabled />
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                        {fieldGroup.from_context ? (
                          <Tooltip content="Click to learn more about this feature" position={Tooltip.positions.RIGHT} zIndex={2000}>
                            <Label
                              text="Dynamic Board"
                              color={Label.colors.PRIMARY}
                              isAnimationDisabled
                              onClick={() => {
                                setShowOptionExplanationModal(true);
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                </div>

                <Modal
                  data-testid="confirm-delete-fieldgroup-modal"
                  show={showConfirmDeleteModal}
                  onClose={() => {
                    setShowConfirmDeleteModal(false);
                  }}
                  width={Modal.width?.DEFAULT as any}
                  alertDialog={false}
                >
                  <ModalHeader title=" " hideCloseButton />
                  <ModalContent>
                    <Box>
                      <div
                        style={{
                          marginTop: '10px',
                        }}
                      >
                        You are about to remove the{' '}
                        <span style={{ whiteSpace: 'nowrap' }}>
                          <span style={{ display: 'inline-block', transform: 'translate3d(0,4px,0)' }}>
                            <Icon icon={BoardIcon} iconSize={20} clickable={false} />
                          </span>{' '}
                          <strong>{board?.name}</strong>
                        </span>{' '}
                        board from{' '}
                        <span style={{ whiteSpace: 'nowrap' }}>
                          <span style={{ display: 'inline-block', transform: 'translate3d(0,4px,0)' }}>
                            <Icon icon={TableIcon} iconSize={20} clickable={false} />
                          </span>{' '}
                          <strong>{sheet?.name}</strong>
                        </span>{' '}
                        in this spreadsheet template configuration. Are you sure to proceed?
                      </div>
                    </Box>
                  </ModalContent>
                  <ModalFooter>
                    <Flex gap={12} justify={Flex.justify?.END as any}>
                      <Button
                        kind={Button.kinds?.TERTIARY as any}
                        onClick={() => {
                          setShowConfirmDeleteModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        color={Button.colors.NEGATIVE}
                        onClick={async () => {
                          setShowConfirmDeleteModal(false);
                          onRemove();
                        }}
                      >
                        Remove
                      </Button>
                    </Flex>
                  </ModalFooter>
                </Modal>

                <div style={{ marginLeft: 'auto' }}>
                  <Flex gap={14}>
                    <Button
                      leftIcon={BoardIcon}
                      kind={Button.kinds?.SECONDARY}
                      size={Button.sizes?.MEDIUM}
                      color={Button.colors?.NEGATIVE}
                      onClick={() => {
                        //onRemove();
                        setShowConfirmDeleteModal(true);
                      }}
                    >
                      Remove board
                    </Button>
                    <>
                      {hasBoardFilters(board!, fieldGroup.board_filters) ? (
                        <Badge color={'primary' as any}>
                          <Button
                            leftIcon={FilterIcon}
                            kind={Button.kinds?.SECONDARY}
                            size={Button.sizes?.MEDIUM}
                            color={areBoardFiltersValid(board!, fieldGroup.board_filters) ? Button.colors.PRIMARY : Button.colors.NEGATIVE}
                            onClick={() => {
                              setShowFilters((prevVal) => !prevVal);
                            }}
                          >
                            Edit filters
                          </Button>
                        </Badge>
                      ) : (
                        <Button
                          leftIcon={FilterIcon}
                          kind={Button.kinds?.SECONDARY}
                          size={Button.sizes?.MEDIUM}
                          color={areBoardFiltersValid(board!, fieldGroup.board_filters) ? Button.colors.PRIMARY : Button.colors.NEGATIVE}
                          onClick={() => {
                            setShowFilters((prevVal) => !prevVal);
                          }}
                        >
                          Add filters
                        </Button>
                      )}
                    </>
                    <Button
                      leftIcon={StatusIcon}
                      disabled={!fields.length}
                      kind={Button.kinds?.SECONDARY}
                      size={Button.sizes?.MEDIUM}
                      className="add_column_button"
                      onClick={() => {
                        onChange({
                          ...fieldGroup,
                          columns: [
                            ...fieldGroup.columns,
                            {
                              path: boardColumnFields[0].path,
                              config: null,
                              id: createRandomId(),
                            },
                          ],
                        });
                      }}
                    >
                      Add column
                    </Button>

                    <Dialog
                      modifiers={[
                        {
                          name: 'preventOverflow',
                          options: {
                            mainAxis: false,
                          },
                        },
                      ]}
                      zIndex={105}
                      shouldShowOnMount={false}
                      showTrigger={[Dialog.hideShowTriggers.CLICK]}
                      hideTrigger={[Dialog.hideShowTriggers.CLICK_OUTSIDE]}
                      position={Dialog.positions.BOTTOM_START}
                      onDialogDidShow={() => setMoreButtonActive(true)}
                      onDialogDidHide={() => setMoreButtonActive(false)}
                      content={
                        <>
                          <DialogContentContainer type={DialogContentContainer.types.POPOVER} size={DialogContentContainer.sizes.SMALL}>
                            <div
                              style={{
                                padding: '10px',
                              }}
                            >
                              <div>
                                <Checkbox
                                  label="Embed column names above items"
                                  checked={!!fieldGroup.show_headlines}
                                  onChange={(ev) => {
                                    onChange({ ...fieldGroup, show_headlines: ev.target.checked });
                                  }}
                                />
                              </div>
                              {board?.type === 'board' ? (
                                <div style={{ marginTop: '10px' }}>
                                  <Checkbox
                                    label="Use as a Dynamic board"
                                    checked={!!fieldGroup.from_context}
                                    onChange={(ev) => {
                                      onChange({ ...fieldGroup, from_context: ev.target.checked });
                                    }}
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                              <div style={{ marginTop: '10px' }}>
                                <Checkbox
                                  label="Disable board during the export"
                                  checked={!!fieldGroup.disable}
                                  onChange={(ev) => {
                                    onChange({ ...fieldGroup, disable: ev.target.checked });
                                  }}
                                />
                              </div>
                            </div>
                          </DialogContentContainer>
                        </>
                      }
                    >
                      <IconButton
                        active={moreButtonActive}
                        icon={MenuIcon}
                        size={IconButton.sizes?.MEDIUM as any}
                        kind={IconButton.kinds?.TERTIARY as any}
                      />
                    </Dialog>
                  </Flex>
                </div>
              </Flex>
            </div>

            <div
              style={{
                zIndex: 98,
                position: 'sticky',
                top: '167px',
                margin: '0 24px',
                height: '10px',
                background: '#f6f7fb',
                boxShadow: '0px 4px 6px -4px rgba(0,0,0,0.4)',
              }}
            ></div>

            <div
              style={{
                position: 'relative',
                margin: '0 32px 42px 32px',
                paddingTop: '10px',
              }}
            >
              <div
                style={{
                  zIndex: 99,
                  position: 'absolute',
                  top: 0,
                  width: 'calc(100% + 64px)',
                  height: '10px',
                  backgroundColor: '#f6f7fb',
                  transform: 'translate3d(-32px,0,0)',
                }}
              ></div>
              {!board ? (
                <Box paddingTop={Box.paddingTops?.LARGE as any} paddingBottom={Box.paddingBottoms?.LARGE as any}>
                  <div
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <p>The connected board was deleted or you lack permissions.</p>
                  </div>
                </Box>
              ) : (
                <>
                  <div style={{ marginTop: '6px' }}>
                    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                      <SortableContext items={fieldGroup.columns} strategy={verticalListSortingStrategy}>
                        {fieldGroup.columns.map((column, idx) => (
                          <Column
                            key={column.id}
                            onSave={onSave}
                            column={column}
                            idx={idx}
                            sheet={sheet}
                            fieldGroup={fieldGroup}
                            template={template}
                            board={board}
                            onChange={(newColumn) => {
                              onChange({
                                ...fieldGroup,
                                columns: fieldGroup.columns.map((c, i) => {
                                  if (i === idx) {
                                    return newColumn;
                                  }
                                  return c;
                                }),
                              });
                            }}
                            onRemove={() => {
                              onChange({
                                ...fieldGroup,
                                columns: fieldGroup.columns.filter((c, i) => i !== idx),
                              });
                            }}
                            fields={fields}
                          />
                        ))}
                      </SortableContext>
                    </DndContext>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
