import React, { useMemo, useState } from 'react';
import { Button, Tooltip } from 'monday-ui-react-core';
// @ts-ignore
import FormulaIcon from 'monday-ui-react-core/dist/icons/Formula';
import { isKeyHotkey } from 'is-hotkey';
import { Transforms, Editor, Range, createEditor, Element as SlateElement, Node } from 'slate';
import { Slate, Editable, withReact, useSlate } from 'slate-react';
import S5SCalc from '@gorilla/spreadsheet-shared/src/lib/external/sjscalc.js';

const withInlines = (editor) => {
  const { isInline } = editor;

  editor.isInline = (element) => ['formula'].includes(element.type) || isInline(element);

  return editor;
};

const Text = (props) => {
  const { attributes, children, leaf } = props;
  let style = {};

  if (leaf.text === '') {
    // @ts-ignore
    style.paddingLeft = '0.1px';
  }

  return (
    <span
      // The following is a workaround for a Chromium bug where,
      // if you have an inline at the end of a block,
      // clicking the end of a block puts the cursor inside the inline
      // instead of inside the final {text: ''} node
      // https://github.com/ianstormtaylor/slate/issues/4704#issuecomment-1006696364
      style={style}
      {...attributes}
    >
      {children}
    </span>
  );
};

const InlineChromiumBugfix = () => (
  <span
    contentEditable={false}
    style={{
      fontSize: 0,
    }}
  >
    {String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);

const FormulaComponent = ({ attributes, children }) => {
  return (
    /*
      Note that this is not a true button, but a span with button-like CSS.
      True buttons are display:inline-block, but Chrome and Safari
      have a bad bug with display:inline-block inside contenteditable:
      - https://bugs.webkit.org/show_bug.cgi?id=105898
      - https://bugs.chromium.org/p/chromium/issues/detail?id=1088403
      Worse, one cannot override the display property: https://github.com/w3c/csswg-drafts/issues/3226
      The only current workaround is to emulate the appearance of a display:inline button using CSS.
    */
    <span
      {...attributes}
      onClick={(ev) => ev.preventDefault()}
      // Margin is necessary to clearly show the cursor adjacent to the button
      style={{
        margin: '0 0.1em',
        backgroundColor: 'white', // baby blue:
        padding: '2px 6px',
        border: '1px solid #767676',
        borderRadius: '2px',
        fontSize: '0.8em',
      }}
    >
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </span>
  );
};

const Element = (props) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case 'formula':
      return <FormulaComponent {...props} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const isFormulaActive = (editor) => {
  const [button] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && (n as any).type === 'formula',
  });
  return !!button;
};

const unwrapButton = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && (n as any).type === 'formula',
  });
};

const insertButton = (editor) => {
  if (editor.selection) {
    wrapButton(editor);
  }
};

const wrapButton = (editor) => {
  if (isFormulaActive(editor)) {
    unwrapButton(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const button = {
    type: 'formula',
    children: isCollapsed ? [{ text: '=SUM(A1:A100)' }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, button);
  } else {
    Transforms.wrapNodes(editor, button, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

const ToggleFormulaButton = () => {
  const editor = useSlate();
  return (
    <>
      <Tooltip content="Insert a formula at your cursors position" position={Tooltip.positions?.RIGHT} zIndex={20000}>
        <Button
          size={Button.sizes?.SMALL}
          kind={Button.kinds?.PRIMARY}
          onMouseDown={(event) => {
            event.preventDefault();
            if (isFormulaActive(editor)) {
              unwrapButton(editor);
            } else {
              insertButton(editor);
            }
          }}
        >
          Insert formula
        </Button>
      </Tooltip>
      {/*
      <button
        onMouseDown={(event) => {
          event.preventDefault();
          if (isFormulaActive(editor)) {
            unwrapButton(editor);
          } else {
            insertButton(editor);
          }
        }}
      >
        Insert formula
      </button>
      */}
    </>
  );
};

const toPreview = (node: any, workbook) => {
  if (!node.children || !node.children.length) {
    return `${Node.string(node)}${node.type === 'paragraph' ? '\n\n' : ''}`;
  }

  const children = node.children.map((n) => toPreview(n, workbook)).join('');

  switch (node.type) {
    case 'formula': {
      try {
        let formula = node?.children[0]?.text || '';
        if (formula[0] === '=') {
          formula = formula.substr(1);
        }
        // @ts-ignore
        if (workbook) {
          return S5SCalc.calculate(formula, workbook, workbook.SheetNames[0]);
        }

        return '{FORMULA_RESULT}';
      } catch (err) {
        console.log('err', err);
        return '#ERR';
      }
    }
    default:
      return `${children}${node.type === 'paragraph' ? '\n\n' : ''}`;
  }
};

type MessageBodyEditorProps = {
  workbook: any;
  value: any;
  onChange: (newValue: any) => void;
};

export const MessageBodyEditor = ({ workbook, value, onChange }: MessageBodyEditorProps) => {
  const editor = useMemo(() => withInlines(withReact(createEditor())), []);
  const [previewText, setPreviewText] = useState(toPreview({ children: value }, workbook));

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    const { selection } = editor;

    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault();
      Transforms.insertText(editor, '\n');
      return;
    }

    // Default left/right behavior is unit:'character'.
    // This fails to distinguish between two cursor positions, such as
    // <inline>foo<cursor/></inline> vs <inline>foo</inline><cursor/>.
    // Here we modify the behavior to unit:'offset'.
    // This lets the user step into and out of the inline without stepping over characters.
    // You may wish to customize this further to only use unit:'offset' in specific cases.

    if (selection && Range.isCollapsed(selection)) {
      const { nativeEvent } = event;
      if (isKeyHotkey('left', nativeEvent)) {
        event.preventDefault();
        Transforms.move(editor, { unit: 'offset', reverse: true });
        return;
      }
      if (isKeyHotkey('right', nativeEvent)) {
        event.preventDefault();
        Transforms.move(editor, { unit: 'offset' });
        return;
      }
    }
  };

  return (
    <Slate
      editor={editor}
      initialValue={value}
      onChange={(newValue) => {
        const isAstChange = editor.operations.some((op) => 'set_selection' !== op.type);
        if (isAstChange) {
          setPreviewText(toPreview({ children: newValue }, workbook));
          onChange(newValue);
        }
      }}
    >
      <div style={{ display: 'flex', gap: '24px' }}>
        <div style={{ width: '50%' }}>
          <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '4px' }}>
            <div style={{ padding: '2px 0', fontSize: '14px', fontWeight: 400 }}>Message text</div>
            <div style={{ marginLeft: 'auto' }}>{/* <ToggleFormulaButton /> */}</div>
          </div>
          <div style={{ position: 'relative' }}>
            <Editable
              renderElement={(props) => <Element {...props} />}
              renderLeaf={(props) => <Text {...props} />}
              onKeyDown={onKeyDown}
              className="messageBodyTextarea"
            />
            <div style={{ position: 'absolute', right: '16px', bottom: '16px' }}>
              <ToggleFormulaButton />
            </div>
          </div>
        </div>
        <div style={{ width: '50%' }}>
          <div style={{ paddingBottom: '4px' }}>
            <div style={{ padding: '2px 0', fontSize: '14px', fontWeight: 400 }}>Preview</div>
          </div>
          <div className="messagePreview">
            <pre>{previewText}</pre>
          </div>
        </div>
      </div>
    </Slate>
  );
};
