import { useMemo } from 'react';
import useSWR from 'swr';
import * as _ from 'lodash';
import { createBatchResolver } from '../utils/batch-resolver';
import { getBoards, getAllBoards, getFolders, Folder, Board } from '@gorilla/common/src/lib/monday-api/api';
import { getConnectedBoardIds } from '@gorilla/common/src/lib/engine/engine';
import { mondayClient } from '../../services/monday';

export type BoardWithFolder = Board & { path: string[]; connected_board_ids: number[] };

function folderToPath(folder: Folder) {
  const path: string[] = [];

  if (_.get(folder, 'parent.name')) {
    path.push(_.get(folder, 'parent.name'));
  }

  path.push(folder.name);

  return path;
}

export const boardBatchResolver = createBatchResolver<number, BoardWithFolder | undefined>(async (boardIds) => {
  const boards = await getBoards(mondayClient, boardIds);
  const uniqFolderIds = _.uniq(boards.flatMap((board) => (board.board_folder_id ? [board.board_folder_id] : [])));
  const folders = await getFolders(mondayClient, uniqFolderIds);
  const foldersById = _.keyBy(folders, 'id');
  const boardsById = _.keyBy(boards, 'id');

  return boardIds.reduce((memo, boardId) => {
    const board = boardsById[boardId];

    if (!board) {
      memo[boardId] = undefined;
      return memo;
    }

    memo[boardId] = {
      ...board,
      path: board.board_folder_id && foldersById[board.board_folder_id] ? folderToPath(foldersById[board.board_folder_id]) : [],
      connected_board_ids: getConnectedBoardIds(board),
    };

    return memo;
  }, {});
});

export function useBoards(boardIds: number[], errorIfNotFound = true) {
  let {
    data: boards,
    isLoading,
    error,
  } = useSWR(`/boards/${boardIds.join('/')}`, async () => (boardIds.length ? boardBatchResolver.resolveItems(boardIds) : []), {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  if (boards && errorIfNotFound && boards.some((board) => !board)) {
    boards = undefined;
    error = new Error('Some boards not found');
  }

  return {
    boards: boards ? (boards.filter((board) => board) as BoardWithFolder[]) : boards,
    isLoading,
    error,
  };
}

export function useBoard(boardId?: number) {
  const { boards, isLoading, error } = useBoards(boardId ? [boardId] : []);

  return {
    board: boards?.[0],
    isLoading,
    error,
  };
}

export function useAllBoards() {
  // TODO: filter out boards that are not of type 'sub_items_board' or 'board'
  const { data: boardsUnfiltered, error } = useSWR(`/all-boards`, async () => _.orderBy(await getAllBoards(mondayClient), 'name'), {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  const boards = useMemo(() => {
    if (!boardsUnfiltered) {
      return boardsUnfiltered;
    }

    return (boardsUnfiltered || []).filter((board) => board.type === 'board' || board.type === 'sub_items_board');
  }, [boardsUnfiltered]);

  const workspaces = useMemo(
    () =>
      _.uniqBy(
        (boards || []).map((board) => board.workspace),
        'id'
      ),
    [boards]
  );

  const boardsById = useMemo(() => _.keyBy(boards || [], 'id'), [boards]);

  function getBoardNameFromId(boardId: number) {
    if (boardsById[boardId]) {
      return boardsById[boardId].name;
    }

    return `Board ${boardId}`;
  }

  return {
    workspaces,
    isLoading: !boards,
    getBoardNameFromId,
    boards,
    boardsById,
    error,
  };
}
