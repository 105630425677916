import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseString } from '../converters';
import { MirrorColumnType, MirrorColumnValue as MirrorColumnValueGraphql } from '../../monday-api/api';

export type LookupColumnValue = {
  text: string | null;
};

const handler: ColumnHandler<MirrorColumnType, LookupColumnValue, unknown, MirrorColumnValueGraphql> = {
  type: 'mirror',

  default_value_field: 'text',

  convertValueFromMondayToEngine: (columnValue, item, board) => {
    const text = parseString(columnValue.display_value);
    let totalMirroredItems: number | null = null;

    try {
      if (item && board) {
        const boardColumn = board.columns.find((column) => column.id === columnValue.id);

        if (boardColumn?.settings_str) {
          const mirrorColumnsSettings = JSON.parse(boardColumn.settings_str);

          if (mirrorColumnsSettings?.relation_column) {
            const connectedBoardColumnIds = Object.entries(mirrorColumnsSettings?.relation_column)
              .filter(([_columnId, isActive]) => isActive)
              .map(([columnId]) => columnId);
            const connectedBoardColumn = connectedBoardColumnIds.length
              ? board.columns.find((column) => column.id === connectedBoardColumnIds[0])
              : null;

            if (connectedBoardColumn) {
              const connectedBoardValue = connectedBoardColumn
                ? item.column_values.find((columnValue) => {
                    return columnValue.id === connectedBoardColumn?.id;
                  })
                : null;

              const connectedBoardValueValue = connectedBoardValue?.value ? JSON.parse(connectedBoardValue.value) : null;

              if (connectedBoardValueValue?.linkedPulseIds && connectedBoardValueValue?.linkedPulseIds.length) {
                totalMirroredItems = connectedBoardValueValue?.linkedPulseIds.length;
              } else {
                totalMirroredItems = 0;
              }
            }
          }
        }
      }
    } catch (err) {}

    return {
      text,
      totalMirroredItems,
    };
  },

  getFormulaValue: (engineValue) => (engineValue.text === null ? '' : engineValue.text),
};

export default handler;
