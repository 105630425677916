import React, { useState, useMemo } from 'react';
import _ from 'lodash';
import { Divider, List, ListItem, IconButton, Box, Flex, Chips, Search, Icon } from 'monday-ui-react-core';
// @ts-ignore
import BoardIcon from 'monday-ui-react-core/dist/icons/Board';
// @ts-ignore
import WorkspaceIcon from 'monday-ui-react-core/dist/icons/Workspace';
// @ts-ignore
import FolderIcon from 'monday-ui-react-core/dist/icons/Folder';
// @ts-ignore
import AddIcon from 'monday-ui-react-core/dist/icons/Add';
import fuzzysort from 'fuzzysort';
import useSWR from 'swr';
import { boardBatchResolver, useAllBoards } from '../hooks/use-boards';
import useDebounce from '../hooks/use-debounce';
import useMonday from '../../hooks/use-monday';
import { BoardBasic } from '@gorilla/common/src/lib/monday-api/api';

type BoardResultProps = {
  board: BoardBasic;
  onBoardAdd: Function;
  title: any;
};

function BoardResult({ board, onBoardAdd, title }: BoardResultProps) {
  const {
    data: boardFull,
    isLoading,
    error,
  } = useSWR(`/board/${board.id}`, async () => boardBatchResolver.resolveItem(board.id), {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  return (
    <ListItem
      size={ListItem.sizes?.LARGE as any}
      className="list-item-board-selection"
      onClick={() => {
        onBoardAdd();
      }}
    >
      <div style={{ position: 'relative', width: '100%' }}>
        <Flex
          align={Flex.align?.START as any}
          justify={Flex.justify?.START as any}
          direction={Flex.directions?.COLUMN as any}
          gap={2}
          wrap={true}
        >
          <Flex>
            <div
              style={{
                flex: 1,
                marginLeft: '0px',
              }}
            >
              <Flex gap={8}>
                <div
                  style={{
                    overflow: 'hidden',
                    maxWidth: '350px',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <strong>{title}</strong>
                </div>
              </Flex>
            </div>
          </Flex>
          <div
            style={{
              transform: 'translate3d(-5px,0,0)',
            }}
          >
            <Flex justify={Flex.justify?.START as any} gap={0}>
              <Chips label={board?.workspace ? board?.workspace.name : 'Main Workspace'} leftIcon={WorkspaceIcon} readOnly />
              {/* 
              {boardFull && boardFull.path && boardFull.path.length ? (
                <Chips label={boardFull.path.join(' ▸ ')} leftIcon={FolderIcon} readOnly className="chips-custom" />
              ) : (
                ''
              )}
                */}
            </Flex>
          </div>
        </Flex>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            right: 0,
            transform: 'translateY(-50%)',
          }}
        >
          <IconButton
            icon={AddIcon}
            kind={IconButton.kinds?.PRIMARY as any}
            size={IconButton.sizes?.XS as any}
            onClick={() => {
              onBoardAdd();
            }}
          />
        </div>
      </div>
    </ListItem>
  );
}

type BoardPickerProps = {
  onBoardSelect: (selectedBoardId: number) => void;
};

export function SingleBoardPicker({ onBoardSelect }: BoardPickerProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [limitToCurrentWorkspace, setLimitToCurrentWorkspace] = useState(false);
  const deferredSearchTerm = useDebounce(searchTerm, 500);
  const { boards, isLoading, error } = useAllBoards();
  const { mondayContext } = useMonday();

  const results = useMemo(() => {
    if (!boards) {
      return [];
    }

    if (!deferredSearchTerm) {
      // TODO: return some default boards?
      return [];
    }

    let filteredBoards = boards;

    if (limitToCurrentWorkspace) {
      const currentBoard = boards.find((board) => board.id === mondayContext?.boardId);
      filteredBoards = boards.filter((board) => _.get(currentBoard, 'workspace.id') === _.get(board, 'workspace.id'));
    }

    const searchResults = fuzzysort.go(deferredSearchTerm, filteredBoards, {
      keys: ['name'],
    });

    return searchResults;
  }, [deferredSearchTerm, boards, limitToCurrentWorkspace]);

  const recentlyUsedBoards = useMemo(() => {
    if (!boards) {
      return [];
    }

    return boards;
  }, [boards]);

  if (error) {
    return (
      <div>
        <strong>Failed to load boards</strong>
      </div>
    );
  }

  if (isLoading || !boards) {
    return (
      <div>
        <strong>Loading boards...</strong>
      </div>
    );
  }

  let resultsContent: React.ReactNode = null;

  if (!deferredSearchTerm) {
    resultsContent = (
      <Box padding={Box.paddings?.SMALL as any}>
        <List>
          <>
            {recentlyUsedBoards.slice(0, 25).map((board) => {
              return (
                <BoardResult
                  key={board.id}
                  title={board.name}
                  board={board}
                  onBoardAdd={() => {
                    onBoardSelect(board.id);
                  }}
                />
              );
            })}
          </>
        </List>
      </Box>
    );
  } else if (!results.length) {
    resultsContent = (
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            width: '100%',
            height: '100%',
          }}
        >
          <div style={{ pointerEvents: 'none', fontWeight: 300, textAlign: 'center', color: '#afb0bd' }}>
            <Icon icon={BoardIcon} iconSize={20} />
            <br />
            No boards found
          </div>
        </div>
      </>
    );
  } else {
    resultsContent = (
      <Box padding={Box.paddings?.SMALL as any}>
        <List>
          <>
            {results.slice(0, 25).map((result) => {
              const title = fuzzysort.highlight(result[0], (m, i) => <strong key={i}>{m}</strong>);

              return (
                <BoardResult
                  key={result.obj.id}
                  title={title}
                  board={result.obj}
                  onBoardAdd={() => {
                    onBoardSelect(result.obj.id);
                  }}
                />
              );
            })}
          </>
        </List>
      </Box>
    );
  }

  return (
    <>
      <div
        style={{
          border: '1px solid #d0d4e4',
          borderRadius: '8px',
        }}
      >
        <Flex gap={0} align={Flex.align?.STRETCH}>
          <div
            style={{
              width: 'calc(100%)',
            }}
          >
            {/* height: '374px' */}
            <div style={{ height: 'calc(90vh - 120px)' }}>
              <div
                style={{
                  padding: '12px',
                  backgroundColor: '#f6f7fb',
                  borderTopLeftRadius: '8px',
                  borderTopRightRadius: '8px',
                }}
              >
                <Search
                  placeholder="Board name"
                  size={Search.sizes?.MEDIUM as any}
                  value={searchTerm}
                  onChange={(newSearchTerm) => {
                    setSearchTerm(newSearchTerm);
                  }}
                  autoFocus
                />
              </div>
              <Divider withoutMargin={true} />
              <div style={{ overflowY: 'auto', height: 'calc(90vh - 185px)' }}>{resultsContent}</div>
            </div>
          </div>
        </Flex>
      </div>
    </>
  );
}
