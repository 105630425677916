import React, { useState } from 'react';
import { Button, Box, Flex, Heading, Divider, Avatar, IconButton, Icon, Tooltip } from 'monday-ui-react-core';
import {
  Template,
  TemplateConfig,
  Sheet as SheetType,
  FieldGroup as FieldGroupType,
} from '@gorilla/spreadsheet-shared/src/lib/spreadsheet-manager/types';
import { FieldGroup } from './FieldGroup';
// @ts-ignore-next-line
import AddSmallIcon from 'monday-ui-react-core/dist/icons/AddSmall';
// @ts-ignore-next-line
import TableIcon from 'monday-ui-react-core/dist/icons/Table';
// @ts-ignore-next-line
import FilterIcon from 'monday-ui-react-core/dist/icons/Filter';
// @ts-ignore-next-line
import StatusIcon from 'monday-ui-react-core/dist/icons/Status';
// @ts-ignore-next-line
import MenuIcon from 'monday-ui-react-core/dist/icons/Menu';
// @ts-ignore-next-line
import BoardIcon from 'monday-ui-react-core/dist/icons/Board';
// @ts-ignore-next-line
import NewTabIcon from 'monday-ui-react-core/dist/icons/NewTab';
import { Board } from '@gorilla/common/src/lib/monday-api/api';
import { getDefaultColumns } from '../hooks/use-spreadsheet-template';
import { BoardPickerModal } from './BoardPickerModal';
import { createRandomId } from '../lib/utils';

type SheetProps = {
  boards: Board[];

  sheet: SheetType;

  config: TemplateConfig;

  template: Template;

  onRemove: () => void;

  onSave: () => void;

  isRemoveable: boolean;

  onFieldGroupAdd: (fieldGroup: FieldGroupType) => void;

  onFieldGroupRemove: (fieldGroup: FieldGroupType) => void;

  onFieldGroupChange: (fieldGroup: FieldGroupType) => void;

  onNameChange: (name: string) => void;

  onOpenBoardManager: () => void;
};

export function Sheet({
  boards,
  sheet,
  config,
  template,
  onRemove,
  onSave,
  isRemoveable,
  onFieldGroupAdd,
  onFieldGroupRemove,
  onFieldGroupChange,
  onNameChange,
  onOpenBoardManager,
}: SheetProps) {
  const [showBoardPicker, setShowBoardPicker] = useState(false);

  return (
    <>
      {showBoardPicker ? (
        <BoardPickerModal
          show={showBoardPicker}
          onClose={async (boardId) => {
            setShowBoardPicker(false);

            if (!boardId) {
              return;
            }

            const columns = await getDefaultColumns(boardId);

            const newFieldGroup: FieldGroupType = {
              id: createRandomId(),
              board_id: boardId,
              show_headlines: false,
              columns,
            };

            onFieldGroupAdd(newFieldGroup);
          }}
        />
      ) : null}

      {sheet.field_groups.length ? (
        <>
          {sheet.field_groups.map((fieldGroup, i) => {
            return (
              <div key={fieldGroup.id}>
                {i !== 0 ? (
                  <>
                    <Divider withoutMargin />
                  </>
                ) : (
                  <></>
                )}

                <FieldGroup
                  boards={boards}
                  config={config}
                  sheet={sheet}
                  fieldGroup={fieldGroup}
                  template={template}
                  onChange={(changedFieldGroup) => {
                    onFieldGroupChange(changedFieldGroup);
                  }}
                  onRemove={() => {
                    onFieldGroupRemove(fieldGroup);
                  }}
                  onSave={onSave}
                />
              </div>
            );
          })}

          <Divider withoutMargin />

          <div
            style={{
              padding: '0px',
              backgroundColor: '#fff',
              borderBottomRightRadius: '8px',
              borderBottomLeftRadius: '8px',
            }}
          >
            <Flex gap={32} align={Flex.align?.CENTER} justify={Flex.justify?.START} direction={Flex.directions?.ROW}>
              <Flex gap={0} align={Flex.align?.CENTER} justify={Flex.justify?.CENTER} direction={Flex.directions?.ROW}>
                <div
                  style={{
                    padding: '22px 22px 22px 24px',
                    minWidth: '80px',
                    borderRight: '1px solid #d0d4e4',
                    lineHeight: '1.3',
                  }}
                >
                  <Flex
                    gap={16}
                    align={Flex.align?.CENTER}
                    justify={Flex.justify?.START}
                    direction={Flex.directions?.ROW}
                    style={{ color: 'rgb(175, 176, 189)' }}
                  >
                    <div style={{ marginRight: 'auto' }}>
                      <strong>Worksheet</strong>
                      <br />
                      {sheet.name}
                    </div>
                  </Flex>
                </div>
                <div
                  style={{
                    padding: '22px 22px 22px 24px',
                    minWidth: '80px',
                    borderRight: '1px solid #d0d4e4',
                    lineHeight: '1.3',
                  }}
                >
                  <Flex
                    gap={16}
                    align={Flex.align?.CENTER}
                    justify={Flex.justify?.START}
                    direction={Flex.directions?.ROW}
                    style={{ color: 'rgb(175, 176, 189)' }}
                  >
                    <div style={{ marginRight: 'auto' }}>
                      <strong>Boards</strong>
                      <br />
                      {sheet.field_groups.length}
                    </div>
                  </Flex>
                </div>
                <div
                  style={{
                    padding: '22px 22px 22px 24px',
                    minWidth: '80px',
                    borderRight: '1px solid #d0d4e4',
                    lineHeight: '1.3',
                  }}
                >
                  <Flex
                    gap={16}
                    align={Flex.align?.CENTER}
                    justify={Flex.justify?.START}
                    direction={Flex.directions?.ROW}
                    style={{ color: 'rgb(175, 176, 189)' }}
                  >
                    <div style={{ marginRight: 'auto' }}>
                      <strong>Columns</strong>
                      <br />
                      {Math.max(...sheet.field_groups.map((fieldGroup) => fieldGroup.columns.length))}
                    </div>
                  </Flex>
                </div>
              </Flex>

              <div style={{ marginRight: '18px', marginLeft: 'auto' }}>
                <Flex gap={14} align={Flex.align?.CENTER} justify={Flex.justify?.CENTER} direction={Flex.directions?.ROW}>
                  <Tooltip content="Choose and append another board to this worksheet" position={Tooltip.positions?.LEFT} zIndex={2000}>
                    <Button
                      leftIcon={AddSmallIcon}
                      size={Button.sizes?.MEDIUM as any}
                      kind={Button.kinds?.PRIMARY as any}
                      onClick={async () => {
                        setShowBoardPicker(true);
                      }}
                    >
                      Append board
                    </Button>
                  </Tooltip>
                  {/* 
                  <Button leftIcon={FilterIcon} size={Button.sizes?.MEDIUM as any} kind={Button.kinds?.SECONDARY as any} disabled>
                    Add filters
                  </Button>
                  <Button
                    leftIcon={StatusIcon}
                    kind={Button.kinds?.SECONDARY}
                    size={Button.sizes?.MEDIUM}
                    className="add_column_button"
                    disabled
                  >
                    Add column
                  </Button>
                  <IconButton icon={MenuIcon} size={IconButton.sizes?.MEDIUM as any} kind={IconButton.kinds?.TERTIARY as any} disabled />
                  */}
                </Flex>
              </div>
            </Flex>
          </div>
        </>
      ) : (
        <>
          <Box margin={Box.margins?.XL as any}>
            <Flex align={Flex.align?.CENTER} justify={Flex.justify?.CENTER} direction={Flex.directions?.ROW}>
              <div
                style={{
                  marginTop: '80px',
                  marginBottom: '64px',
                  textAlign: 'center',
                }}
              >
                <Flex gap={10} align={Flex.align?.CENTER} justify={Flex.justify?.CENTER} direction={Flex.directions?.COLUMN}>
                  <Heading type={Heading.types?.h2 as any} value="Where did all the boards go?" />
                  <p style={{ marginTop: '0px' }}>Give this worksheet some company and add a board.</p>
                  <Button
                    leftIcon={BoardIcon}
                    size={Button.sizes?.MEDIUM as any}
                    kind={Button.kinds?.PRIMARY as any}
                    onClick={() => {
                      setShowBoardPicker(true);
                    }}
                  >
                    Add board
                  </Button>
                </Flex>
              </div>
            </Flex>
          </Box>
        </>
      )}
    </>
  );
}
