// @ts-nocheck
import React, { useState } from 'react';
import _ from 'lodash';
import { Board } from '@gorilla/common/src/lib/monday-api/api';
import { getSortableFields, areBoardFiltersValid, BoardFilters } from '@gorilla/common/src/lib/rules';
import statusColumnHandler from '@gorilla/common/src/lib/engine/column_handlers/status_column_handler';
// @ts-ignore
import DeleteIcon from 'monday-ui-react-core/dist/icons/Delete';
// @ts-ignore
import BoardIcon from 'monday-ui-react-core/dist/icons/Board';
// @ts-ignore
import LearnMoreIcon from 'monday-ui-react-core/dist/icons/LearnMore';
// @ts-ignore
import FilterIcon from 'monday-ui-react-core/dist/icons/Filter';
import { Heading } from 'monday-ui-react-core/next';
import {
  Button,
  IconButton,
  Box,
  Flex,
  TextField,
  Text,
  Dropdown,
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Divider,
  Icon,
  Counter,
  Tooltip,
} from 'monday-ui-react-core';
import {
  Rule,
  Rules,
  FilterableField,
  getFilterableFields,
  OPERATORS,
  COMPARE_ATTRIBUTES,
  COMPARE_VALUES,
  validateRule,
  getNormalizedBoardFilters,
} from '@gorilla/common/src/lib/rules';

type StringValueProps = {
  value: any;
  board: Board;
  columnId: string;
  description?: string;
  onChange: (newValue: string) => void;
};

function replaceDangerousChars(str: string) {
  return str.replace(/\\/g, '');
}

function StringValue({ value, onChange, description }: StringValueProps) {
  return (
    <div style={{ maxWidth: '200px' }}>
      <div>
        <Tooltip content={description} position={Tooltip.positions?.TOP} zIndex={10000}>
          <TextField
            value={replaceDangerousChars(value || '')}
            onChange={(newValue) => {
              onChange(replaceDangerousChars(newValue || ''));
            }}
            size={TextField.sizes.MEDIUM}
            validation={{
              //text: description,
            }}
            required
            iconName={description ? LearnMoreIcon : ''}
          />
        </Tooltip>
      </div>
    </div>
  );
}

type NumberValueProps = {
  value: any;
  board: Board;
  columnId: string;
  description?: string;
  onChange: (newValue: number | null) => void;
};

function NumberValue({ value, onChange, description }: NumberValueProps) {
  return (
    <div style={{ maxWidth: '200px' }}>
      <div>
        <Tooltip content={description} position={Tooltip.positions?.TOP} zIndex={10000}>
          <TextField
            value={value || ''}
            onChange={(newValue) => {
              onChange(newValue === '' ? null : +newValue);
            }}
            size={TextField.sizes.MEDIUM}
            validation={{
              //text: description,
            }}
            type={TextField.types.NUMBER}
            required
            iconName={description ? LearnMoreIcon : ''}
          />
        </Tooltip>
      </div>
    </div>
  );
}

type StringPairValueProps = {
  value: any;
  board: Board;
  columnId: string;
  description?: string;
  onChange: (newValue: [string, string]) => void;
};

function StringPairValue({ value, onChange, description }: StringPairValueProps) {
  const normalizedValue = _.isArray(value) && value.length === 2 ? value : ['', ''];

  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      <div>
        <div style={{ width: '95px' }}>
          <Tooltip content={description} position={Tooltip.positions?.TOP} zIndex={10000}>
            <TextField
              value={replaceDangerousChars(normalizedValue[0] || '')}
              onChange={(newValue) => {
                onChange([replaceDangerousChars(newValue), replaceDangerousChars(normalizedValue[1])]);
              }}
              size={TextField.sizes.MEDIUM}
              validation={{
                //text: description,
              }}
              required
              iconName={description ? LearnMoreIcon : ''}
            />
          </Tooltip>
        </div>
      </div>
      <div>
        <div style={{ width: '95px' }}>
          <Tooltip content={description} position={Tooltip.positions?.TOP} zIndex={10000}>
            <TextField
              value={replaceDangerousChars(normalizedValue[1] || '')}
              onChange={(newValue) => {
                onChange([replaceDangerousChars(normalizedValue[0]), replaceDangerousChars(newValue)]);
              }}
              size={TextField.sizes.MEDIUM}
              validation={{
                //text: description,
              }}
              required
              iconName={description ? LearnMoreIcon : ''}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

type StatusValueProps = {
  value: any;
  board: Board;
  columnId: string;
  description?: string;
  onChange: (newValue: number) => void;
};

export function StatusValue({ columnId, board, value, onChange }: StatusValueProps) {
  const statusColumn = board.columns.find((column) => column.id === columnId);
  let options: { value: number; label: string }[] = [];

  if (statusColumn?.type === 'status' && statusColumn.settings_str) {
    const settings = statusColumnHandler.convertSettingsToEngine!(statusColumn.settings_str);

    if (_.isObject(_.get(settings, 'labels'))) {
      options = Object.entries(settings.labels).map(([key, value]) => ({ value: +key, label: value }));
    }

    if (_.isNumber(value) && !options.find((option) => option.value === value)) {
      options.push({ value, label: `Unknown (${value})` });
    }
  }

  const selectedOption = options.find((option) => option.value === value);

  return (
    <div>
      <div style={{ width: '200px' }}>
        <Dropdown
          options={options}
          value={selectedOption}
          placeholder="Select Status"
          insideOverflowContainer={true}
          clearable={false}
          onChange={(newStatus) => {
            onChange(newStatus.value);
          }}
        />
      </div>
    </div>
  );
}

type GroupValueProps = {
  value: any;
  board: Board;
  columnId: string;
  description?: string;
  onChange: (newValue: string) => void;
};

function GroupValue({ columnId, board, value, onChange }: GroupValueProps) {
  let options: { value: number; label: string }[] = (board.groups || []).map((group) => ({ value: group.id, label: group.title }));

  if (value && !options.find((option) => option.value === value)) {
    // group doesn't exist anymore
    options.push({ value, label: `Unknown (${value})` });
  }

  const selectedOption = options.find((option) => option.value === value);

  return (
    <div>
      <div style={{ width: '200px' }}>
        <Dropdown
          options={options}
          value={selectedOption}
          placeholder="Select Group"
          insideOverflowContainer={true}
          clearable={false}
          onChange={(newGroup) => {
            onChange(newGroup.value);
          }}
        />
      </div>
    </div>
  );
}

const compareValueComponents = {
  string: StringValue,
  number: NumberValue,
  string_pair: StringPairValue,
  status: StatusValue,
  group: GroupValue,
};

type RuleRowProps = {
  board: Board;
  filterableFields: FilterableField[];
  partialRule: PartialRule;
  showErrors?: boolean;
  onChange: (partialRule: PartialRule) => void;
};

function RuleRow({ board, filterableFields, partialRule, onChange, showErrors }: RuleRowProps) {
  const fieldOptions = filterableFields.map((filterableField) => ({
    value: filterableField.id,
    label: filterableField.title,
  }));
  const selectedField = filterableFields.find((filterableField) => filterableField.id === partialRule.column_id);
  const selectedFieldOption = selectedField ? { value: selectedField.id, label: selectedField.title } : null;
  const showOperatorDropdown = !!selectedField;
  const validateMessage = validateRule(board, partialRule);

  const operatorOptions = (selectedField?.supported_rules || []).map((supportedRule) => ({
    label: OPERATORS[supportedRule.operator]?.label || supportedRule.operator,
    value: supportedRule.operator,
  }));

  const selectedOperatorOption = operatorOptions.find((operatorOption) => operatorOption.value === partialRule.operator);
  const supportedRule = selectedField?.supported_rules.find((supportedRule) => supportedRule.operator === partialRule.operator);
  const ruleHasAttributes =
    supportedRule &&
    'compare_attributes_ids' in supportedRule &&
    supportedRule.compare_attributes_ids &&
    supportedRule.compare_attributes_ids.length;
  const showAttributesDropdown = ruleHasAttributes;

  const attributeOptions = (supportedRule?.compare_attributes_ids || []).map((attributeId) => {
    return {
      label: COMPARE_ATTRIBUTES[attributeId].label || attributeId,
      value: attributeId,
    };
  });

  const selectedAttributeOption = attributeOptions.find((attributeOption) => attributeOption.value === partialRule.compare_attribute_id);
  const isAttributesDropownValid = ruleHasAttributes ? !!selectedAttributeOption : true;
  const showCompareValueDropdown =
    isAttributesDropownValid &&
    supportedRule &&
    'compare_value_ids' in supportedRule &&
    supportedRule.compare_value_ids &&
    supportedRule.compare_value_ids.length;

  const compareValueOptions = (supportedRule?.compare_value_ids || []).map((compareValueId) => {
    return {
      label: COMPARE_VALUES[compareValueId].label || compareValueId,
      value: compareValueId,
    };
  });

  const selectedCompareValueOption = compareValueOptions.find(
    (compareValueOption) => compareValueOption.value === _.get(partialRule, 'compare_value.0.id')
  );

  let compareValueComp: React.ReactNode | null = null;

  if (
    selectedCompareValueOption &&
    'type' in COMPARE_VALUES[selectedCompareValueOption.value] &&
    compareValueComponents[COMPARE_VALUES[selectedCompareValueOption.value]['type']]
  ) {
    const CompareValueComponent = compareValueComponents[COMPARE_VALUES[selectedCompareValueOption.value]['type']];

    compareValueComp = (
      <div>
        <CompareValueComponent
          columnId={partialRule.column_id}
          board={board}
          value={_.get(partialRule, 'compare_value.0.value')}
          onChange={(newValue) => {
            onChange({
              ...partialRule,
              compare_value: [{ id: selectedCompareValueOption.value, value: newValue }],
            });
          }}
          description={COMPARE_VALUES[selectedCompareValueOption.value]['description'] || ''}
        />
      </div>
    );
  }

  return (
    <>
      <div style={{ display: 'flex', gap: '10px' }}>
        <div>
          <div style={{ width: '200px' }}>
            <Dropdown
              options={fieldOptions}
              value={selectedFieldOption}
              placeholder="Select Column"
              insideOverflowContainer={true}
              clearable={false}
              onChange={(newField) => {
                onChange(
                  _.omit(
                    {
                      ...partialRule,
                      column_id: newField.value,
                    },
                    ['operator', 'compare_attribute_id', 'compare_value']
                  )
                );
              }}
            />
          </div>
        </div>
        {showOperatorDropdown && (
          <div>
            <div style={{ width: '200px' }}>
              <Dropdown
                key={partialRule.column_id}
                options={operatorOptions}
                value={selectedOperatorOption}
                placeholder="Select Operator"
                insideOverflowContainer={true}
                clearable={false}
                onChange={(newOperator) => {
                  onChange(
                    _.omit(
                      {
                        ...partialRule,
                        operator: newOperator.value,
                      },
                      ['compare_attribute_id', 'compare_value']
                    )
                  );
                }}
              />
            </div>
          </div>
        )}
        {showAttributesDropdown && (
          <div>
            <div style={{ width: '200px' }}>
              <Dropdown
                key={partialRule.operator}
                options={attributeOptions}
                value={selectedAttributeOption}
                placeholder="Select Attribute"
                insideOverflowContainer={true}
                clearable={false}
                onChange={(newAttribute) => {
                  onChange(
                    _.omit(
                      {
                        ...partialRule,
                        compare_attribute_id: newAttribute.value,
                      },
                      ['compare_value']
                    )
                  );
                }}
              />
            </div>
          </div>
        )}
        {showCompareValueDropdown && (
          <div>
            <div style={{ width: '200px' }}>
              <Dropdown
                key={`${partialRule.operator}__${partialRule.compare_attribute_id || 'none'}`}
                options={compareValueOptions}
                value={selectedCompareValueOption}
                placeholder="Select Value"
                insideOverflowContainer={true}
                clearable={false}
                onChange={(newCompareValue) => {
                  onChange({
                    ...partialRule,
                    compare_value: [{ id: newCompareValue.value, value: null }],
                  });
                }}
              />
            </div>
          </div>
        )}
        {compareValueComp}
      </div>

      {showErrors && validateMessage && (
        <div style={{ color: 'red' }}>
          <small>{validateMessage.error}</small>
        </div>
      )}
    </>
  );
}

type PartialRule = Partial<Rule>;

type BoardRulesModalProps = {
  board: Board;
  isOpen: boolean;
  hideOrderBy?: boolean;
  hideCondition?: boolean;
  inititalBoardFilters?: BoardFilters;
  onClose: (newBoardFilters?: BoardFilters) => void;
};

export function BoardRulesModal({ board, isOpen, inititalBoardFilters, onClose, hideOrderBy, hideCondition }: BoardRulesModalProps) {
  const normalizedInititalBoardFilters = getNormalizedBoardFilters(board, inititalBoardFilters) || {};
  const [rules, setRules] = useState<PartialRule[]>(normalizedInititalBoardFilters?.rules || []);
  const [newRule, setNewRule] = useState<PartialRule>({});
  const [condition, setCondition] = useState(normalizedInititalBoardFilters?.operator || 'and');
  const [sortColumnId, setColumnId] = useState(normalizedInititalBoardFilters?.order_by_column_id || null);
  const [sortDirection, setSortDirection] = useState(normalizedInititalBoardFilters?.order_by_direction || null);
  const filterableFields = getFilterableFields(board);
  const sortableFields = getSortableFields(board);
  const allRulesValid = rules.every((rule) => !validateRule(board, rule));
  const newRuleValidation = validateRule(board, newRule);
  const showErrors = !areBoardFiltersValid(board, normalizedInititalBoardFilters);

  const sortableFieldOptions = sortableFields.map((sortableField) => ({
    value: sortableField.id,
    label: sortableField.title,
  }));

  const sortDirectionOptions = [
    { label: 'Ascending', value: 'asc' },
    { label: 'Descending', value: 'desc' },
  ];

  return (
    <Modal
      contentSpacing={false}
      alertDialog={true}
      data-testid="board-rules-modal"
      width={Modal.width.FULL_WIDTH}
      show={isOpen}
      onClose={() => {
        onClose();
      }}
      title=" " 
      description="" 
      closeButtonAriaLabel=""
    >
      <ModalContent>
        <Flex direction={Flex.directions?.ROW} align={Flex.align?.CENTER} gap={4} style={{ marginBottom: '24px' }}>
          <Flex direction={Flex.directions?.ROW} align={Flex.align?.CENTER} gap={8}>
            <Icon icon={FilterIcon} iconSize={24} clickable={false} />
            <Heading
              type={Heading.types?.H3}
              weight={Heading.weights?.BOLD}
              align={Heading.align?.START}
            >
              Board Filters
            </Heading>
          </Flex>
        </Flex>
        
        <Box
          marginBottom={Box.marginBottoms?.LARGE}
          paddingStart={Box.paddingStarts?.MEDIUM}
          paddingEnd={Box.paddingEnds?.MEDIUM}
          border={Box.borders?.DEFAULT}
          rounded={Box.roundeds?.SMALL}
          backgroundColor={Box.backgroundColors?.ALL_GREY_BACKGROUND_COLOR}
        >
          <Flex gap={16}>
            {!hideCondition && (
              <>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', width: '200px', height: '100px' }}>
                  <small>
                    <strong>Condition</strong>
                  </small>
                  <div style={{ marginTop: '10px', width: '100%' }}>
                    <Dropdown
                      options={[
                        { label: 'And', value: 'and' },
                        { label: 'Or', value: 'or' },
                      ]}
                      value={{ label: condition === 'and' ? 'And' : 'Or', value: condition }}
                      placeholder="Select Condition"
                      insideOverflowContainer={true}
                      clearable={false}
                      onChange={(newCondition) => {
                        setCondition(newCondition.value);
                      }}
                      style={{
                        display: 'block',
                        width: '100%'
                      }}
                    />
                  </div>
                </div>
                <div style={{ height: '100px' }}>
                  <Divider direction={Divider.directions?.VERTICAL} withoutMargin />
                </div>
              </>
            )}
            {!hideOrderBy && (
              <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', width: '100%', height: '100px' }}>
                <small>
                  <strong>Sorting</strong>
                </small>
                <div style={{ display: 'flex', gap: '10px', marginTop: '10px', width: '100%' }}>
                  <div style={{ width: '100%' }}>
                    <div style={{ width: '100%' }}>
                      <Dropdown
                        options={sortableFieldOptions}
                        value={sortableFieldOptions.find((option) => option.value === sortColumnId)}
                        placeholder="No Sorting"
                        insideOverflowContainer={true}
                        clearable={true}
                        onChange={(newColumnId) => {
                          if (newColumnId) {
                            setColumnId(newColumnId.value);
                            if (!sortDirection) {
                              setSortDirection('asc');
                            }
                          } else {
                            setColumnId(null);
                            setSortDirection(null);
                          }
                        }}
                        style={{
                          display: 'block',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                  {sortColumnId && (
                    <div>
                      <div style={{ width: '200px' }}>
                        <Dropdown
                          options={sortDirectionOptions}
                          value={sortDirectionOptions.find((option) => option.value === sortDirection)}
                          placeholder="Select Direction"
                          insideOverflowContainer={true}
                          clearable={false}
                          onChange={(newSortDirection) => {
                            setSortDirection(newSortDirection ? newSortDirection.value : null);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            
          </Flex>
        </Box>
        <Box border={Box.borders?.DEFAULT} rounded={Box.roundeds?.SMALL} backgroundColor={Box.backgroundColors?.ALL_GREY_BACKGROUND_COLOR}>
          <div style={{ padding: '16px' }}>
            {/*<small><strong>New rule</strong></small>*/}
            <div style={{ display: 'flex', gap: '10px' }}>
              <div>
                <RuleRow
                  board={board}
                  filterableFields={filterableFields}
                  partialRule={newRule}
                  onChange={(newPartialRule) => {
                    setNewRule(newPartialRule);
                  }}
                />
              </div>
              <div>
                <Button
                  disabled={!!newRuleValidation}
                  onClick={() => {
                    setRules([...rules, newRule]);
                    setNewRule({});
                  }}
                >
                  Add rule
                </Button>
              </div>
            </div>
          </div>
          <Divider withoutMargin />
          <div style={{ overflowY: 'auto', padding: '16px', backgroundColor: '#fff' }}>
            {rules.length > 0 ? (
              <>
                <div style={{ height: 'calc(100vh - 490px)' }}>
                  {rules.map((rule, index) => {
                    return (
                      <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
                        <div style={{ marginRight: '10px' }}>
                          <Counter count={index+1} color={Counter.colors?.DARK} noAnimation />
                        </div>
                        <div>
                          <RuleRow
                            board={board}
                            filterableFields={filterableFields}
                            partialRule={rule}
                            showErrors={showErrors}
                            onChange={(newPartialRule) => {
                              setRules(
                                rules.map((r, i) => {
                                  return i === index ? newPartialRule : r;
                                })
                              );
                            }}
                          />
                        </div>
                        <div style={{ marginLeft: 'auto' }}>
                          <Button
                            size={Button.sizes?.MEDIUM}
                            kind={Button.kinds?.SECONDARY}
                            onClick={() => {
                              setRules(rules.filter((r, i) => i !== index));
                            }}
                          >
                            Remove
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                  <div style={{ height: '24px' }}></div>
                </div>
              </>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 480px)' }}>
                <Flex direction={Flex.directions?.COLUMN} gap={8}>
                  <Heading
                    type={Heading.types?.H3}
                    weight={Heading.weights?.BOLD}
                    align={Heading.align?.START}
                  >
                    No rules defined
                  </Heading>
                  <Text align={Text.align?.CENTER}>
                    Add a new rule by selecting a column from the <br />
                    dropdown above,  and define its conditional <br />
                    operators and values as displayed.
                  </Text>
                </Flex>
              </div>
            )}
          </div>
        </Box>
        <Flex gap={12} justify={Flex.justify?.END as any} style={{ marginTop: '24px' }}>
          <Box marginEnd={Box.marginEnds?.AUTO as any}>
            <Text>
              Define filters for the <Icon icon={BoardIcon} clickable={false} style={{ transform: 'translate3d(0,3px,0)' }} /> <strong>{board.name}</strong> board by adding rules.
            </Text>
          </Box>
          <Button
            disabled={!allRulesValid}
            onClick={() => {
              const newBoardFilters: BoardFilters = {};

              if (rules.length) {
                newBoardFilters.rules = rules as Rules;
              }

              if (condition) {
                newBoardFilters.operator = condition;
              }

              if (sortColumnId) {
                newBoardFilters.order_by_column_id = sortColumnId;

                if (sortDirection) {
                  newBoardFilters.order_by_direction = sortDirection;
                }
              }

              onClose(newBoardFilters);
            }}
          >
            Apply
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
