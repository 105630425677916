import React, { useState } from 'react';
import * as _ from 'lodash';
import { Modal, ModalHeader, ModalContent, Button, Steps, Flex, Checkbox, RadioButton, Box, TextField, Icon } from 'monday-ui-react-core';
import { createSignal } from '../services/signals';
// @ts-ignore
import AcademyIcon from 'monday-ui-react-core/dist/icons/Academy';
// @ts-ignore
import MoveArrowRightIcon from 'monday-ui-react-core/dist/icons/MoveArrowRight';
// @ts-ignore
import PlayIcon from 'monday-ui-react-core/dist/icons/Play';

type IntroductionModalProps = {
  show: boolean;
  onClose: () => void;
};

export function IntroductionModal({ show, onClose }: IntroductionModalProps) {
  const [otherText, setOtherText] = useState<string>('');
  const [formState, setFormState] = useState<Record<string, boolean>>({
    export_to_google_sheets: false,
    import_from_google_sheets: false,
    two_way_sync: false,
    none_of_these: false,
    other: false,
  });

  const [activeStepIndexOnboarding, setActiveStepIndexOnboarding] = useState<number>(
    window.localStorage.getItem('seen-introduction') === 'yes' ? 1 : 0
  );

  return (
    <Modal
      show={show}
      onClose={() => {
        setActiveStepIndexOnboarding(0);
        onClose();
      }}
      width={Modal.width?.DEFAULT as any}
      alertDialog={true}
      id="super-empty-state-modal"
      data-testid=""
    >
      <ModalHeader title="Onboarding" hideCloseButton={true} />
      <ModalContent>
        <div>
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '200px',
              backgroundColor: '#f6f7fb',
              lineHeight: 0,
            }}
          >
            <div
              style={{
                pointerEvents: 'none',
                position: 'relative',
              }}
            >
              <img src="/introduction-welcome-5.png" width="640" height="200" alt="" />
            </div>
          </div>
          <div
            style={{
              padding: '54px 54px 0px 54px',
              marginBottom: '120px',
              textAlign: 'center',
            }}
          >
            <Flex gap={10} justify={Flex.justify?.CENTER}>
              <h2>Welcome to Spreadsheet Gorilla</h2>
            </Flex>
            <p>
              Before you get started, we do recommend to take a brief look at <br />
              our{' '}
              <span style={{ display: 'inline-block', marginLeft: '2px', transform: 'translate3d(0,4px,0)' }}>
                <Icon icon={AcademyIcon} iconSize={18} ignoreFocusStyle clickable={false} />
              </span>{' '}
              <a
                href="https://getgorilla.app/blog/why-should-i-make-use-of-spreadsheet-gorilla-for-monday-com"
                target="_blank"
                style={{ fontSize: '16px', fontWeight: 600, color: '#323338' }}
              >
                How to use
              </a>{' '}
              guide and the{' '}
              <span style={{ display: 'inline-block', marginLeft: '2px', transform: 'translate3d(0,4px,0)' }}>
                <Icon icon={PlayIcon} iconSize={18} ignoreFocusStyle clickable={false} />
              </span>{' '}
              <a
                href="https://www.youtube.com/playlist?list=PLNt6YgM15GuHMoUhBM0fH1Ji_ARxdiQ2p"
                target="_blank"
                style={{ fontSize: '16px', fontWeight: 600, color: '#323338' }}
              >
                Video tutorials
              </a>
              . Both do
              <br />
              provide helpful information on the concept and features.
            </p>
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: '48px',
              left: '50%',
              transform: 'translate3d(-50%,0,0)',
            }}
          >
            <Flex gap={12}>
              <Button
                rightIcon={MoveArrowRightIcon}
                kind={Button.kinds?.PRIMARY as any}
                size={Button.sizes?.MEDIUM as any}
                onClick={() => {
                  onClose();
                }}
              >
                Get started
              </Button>
            </Flex>
          </div>
          {/* 
          <div
            style={{
              position: 'absolute',
              right: '32px',
              bottom: '32px',
            }}
          >
            <Button
              kind={Button.kinds?.PRIMARY as any}
              size={Button.sizes?.MEDIUM as any}
              leftIcon={AcademyIcon}
              onClick={() => {
                window.open('https://getgorilla.app/blog/why-should-i-make-use-of-spreadsheet-gorilla-for-monday-com', '_blank');
              }}
            >
              How to use
            </Button>
          </div>
          */}
        </div>
        {/*
        <Steps
          isContentOnTop
          activeStepIndex={activeStepIndexOnboarding}
          onChangeActiveStep={(e, stepIndexOnboarding) => {
            setActiveStepIndexOnboarding(stepIndexOnboarding);
          }}
          areNavigationButtonsHidden
          steps={[
            <div style={{ position: 'relative' }}>
              <div
                style={{
                  padding: '54px 54px 32px 54px',
                  textAlign: 'left',
                }}
              >
                <h2>Which feature should we build next?</h2>
                <Box
                  marginTop={Box.marginTops?.LARGE as any}
                  marginBottom={Box.marginBottoms?.LARGE as any}
                  paddingBottom={Box.paddingBottoms?.LARGE as any}
                >
                  <Box paddingBottom={Box.paddingBottoms?.MEDIUM as any}>
                    <RadioButton
                      text="Export spreadsheets to Google Sheets"
                      name="radio-buttons-group-intro"
                      checked={formState.export_to_google_sheets}
                      onSelect={() => {
                        setFormState({ ...formState, export_to_google_sheets: !formState.export_to_google_sheets });
                      }}
                    />
                  </Box>
                  <Box paddingBottom={Box.paddingBottoms?.MEDIUM as any}>
                    <RadioButton
                      text="Import items from Google Sheets"
                      name="radio-buttons-group-intro"
                      checked={formState.import_from_google_sheets}
                      onSelect={() => {
                        setFormState({ ...formState, import_from_google_sheets: !formState.import_from_google_sheets });
                      }}
                    />
                  </Box>
                  <Box paddingBottom={Box.paddingBottoms?.MEDIUM as any}>
                    <RadioButton
                      text="Two-way sync between Google Sheets and monday.com boards"
                      name="radio-buttons-group-intro"
                      checked={formState.two_way_sync}
                      onSelect={() => {
                        setFormState({ ...formState, two_way_sync: !formState.two_way_sync });
                      }}
                    />
                  </Box>
                  <Box paddingBottom={Box.paddingBottoms?.MEDIUM as any}>
                    <RadioButton
                      text="None of the above"
                      name="radio-buttons-group-intro"
                      checked={formState.none_of_these}
                      onSelect={() => {
                        setFormState({ ...formState, none_of_these: !formState.none_of_these });
                      }}
                    />
                  </Box>
                </Box>
              </div>
              <div
                style={{
                  zIndex: '100',
                  position: 'absolute',
                  bottom: '-30px',
                  left: '38px',
                  width: '400px',
                  background: '#ffffff',
                }}
              >
                Please select one from the options above.
              </div>
              <div
                style={{
                  position: 'absolute',
                  right: '32px',
                  bottom: '-40px',
                }}
              >
                <Flex gap={12}>
                  <Button
                    kind={Button.kinds?.PRIMARY as any}
                    size={Button.sizes?.MEDIUM as any}
                    onClick={async () => {
                      setActiveStepIndexOnboarding(activeStepIndexOnboarding + 1);
                      const signals = Object.keys(formState)
                        .filter((key) => formState[key])
                        .map((key) => `interest_${key}`);

                      for (const signal of signals) {
                        await createSignal(signal);
                      }

                      if (otherText) {
                        await createSignal(`interest_other_text: ${otherText}`);
                      }
                    }}
                    disabled={Object.values(formState).filter((value) => value).length === 0}
                  >
                    Get started
                  </Button>
                </Flex>
              </div>
            </div>,
            <div>
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  height: '200px',
                  backgroundColor: '#f6f7fb',
                  lineHeight: 0,
                }}
              >
                <div
                  style={{
                    pointerEvents: 'none',
                    position: 'relative',
                  }}
                >
                  <img src="/introduction-welcome-5.png" width="640" height="200" alt="" />
                </div>
              </div>
              <div
                style={{
                  padding: '32px 54px',
                  textAlign: 'center',
                }}
              >
                <Flex gap={10} justify={Flex.justify?.CENTER}>
                  <h2>Welcome to Spreadsheet Gorilla</h2>
                </Flex>
                <p>
                  Our mission is to cover everything related to spreadsheets with this app. It's still in development and we keep adding or
                  changing features. Let's take a quick look at the app concept to get a better understanding.
                </p>
              </div>
              <div
                style={{
                  position: 'absolute',
                  bottom: '32px',
                  left: '32px',
                }}
              >
                <Button
                  kind={Button.kinds?.TERTIARY as any}
                  size={Button.sizes?.MEDIUM as any}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Skip
                </Button>
              </div>
              <div
                style={{
                  position: 'absolute',
                  right: '32px',
                  bottom: '32px',
                }}
              >
                <Flex gap={12}>
                  <Button
                    kind={Button.kinds?.PRIMARY as any}
                    size={Button.sizes?.MEDIUM as any}
                    onClick={() => {
                      setActiveStepIndexOnboarding(activeStepIndexOnboarding + 1);
                    }}
                  >
                    Next
                  </Button>
                </Flex>
              </div>
            </div>,
            <div>
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  height: '200px',
                  backgroundColor: '#f6f7fb',
                  lineHeight: 0,
                }}
              >
                <div
                  style={{
                    pointerEvents: 'none',
                    position: 'relative',
                  }}
                >
                  <img src="/introduction-spreadsheet-templates-2.png" width="640" height="200" alt="" />
                  <div
                    style={{
                      position: 'absolute',
                      top: '70%',
                      right: 0,
                      bottom: 0,
                      left: 0,
                      background: 'linear-gradient(0deg, rgba(246,247,251,1) 0%, rgba(246,247,251,0) 100%)',
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  padding: '32px 54px',
                  textAlign: 'center',
                }}
              >
                <Flex gap={10} justify={Flex.justify?.CENTER}>
                  <h2>Spreadsheet templates</h2>
                </Flex>
                <p>
                  Templates can be considered as headless or virtual spreadsheets and are your starting point for everything. Use them to
                  configure your desired spreadsheet format, like an Excel file or for updating board items.
                </p>
              </div>
              <div
                style={{
                  position: 'absolute',
                  bottom: '32px',
                  left: '32px',
                }}
              >
                <Button
                  kind={Button.kinds?.TERTIARY as any}
                  size={Button.sizes?.MEDIUM as any}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Skip
                </Button>
              </div>
              <div
                style={{
                  position: 'absolute',
                  right: '32px',
                  bottom: '32px',
                }}
              >
                <Flex gap={12}>
                  <Button
                    kind={Button.kinds?.TERTIARY as any}
                    size={Button.sizes?.MEDIUM as any}
                    onClick={() => {
                      setActiveStepIndexOnboarding(activeStepIndexOnboarding - 1);
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    kind={Button.kinds?.PRIMARY as any}
                    size={Button.sizes?.MEDIUM as any}
                    onClick={() => {
                      setActiveStepIndexOnboarding(activeStepIndexOnboarding + 1);
                    }}
                  >
                    Next
                  </Button>
                </Flex>
              </div>
            </div>,
            <div>
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  height: '200px',
                  backgroundColor: '#f6f7fb',
                  lineHeight: 0,
                }}
              >
                <div
                  style={{
                    pointerEvents: 'none',
                    position: 'relative',
                  }}
                >
                  <img src="/introduction-updating-board-items-1.png" width="640" height="200" alt="" />
                  <div
                    style={{
                      position: 'absolute',
                      top: '70%',
                      right: 0,
                      bottom: 0,
                      left: 0,
                      background: 'linear-gradient(0deg, rgba(246,247,251,1) 0%, rgba(246,247,251,0) 100%)',
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  padding: '32px 54px',
                  textAlign: 'center',
                }}
              >
                <Flex gap={10} justify={Flex.justify?.CENTER}>
                  <h2>Updating board items</h2>
                </Flex>
                <p>
                  Templates are a versatile and powerful toolkit. About 200 functions let you build formulas to perform simple or complex
                  cross-worksheet calculations and return their results to the related boards.
                </p>
              </div>
              <div
                style={{
                  position: 'absolute',
                  bottom: '32px',
                  left: '32px',
                }}
              >
                <Button
                  kind={Button.kinds?.TERTIARY as any}
                  size={Button.sizes?.MEDIUM as any}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Skip
                </Button>
              </div>
              <div
                style={{
                  position: 'absolute',
                  right: '32px',
                  bottom: '32px',
                }}
              >
                <Flex gap={12}>
                  <Button
                    kind={Button.kinds?.TERTIARY as any}
                    size={Button.sizes?.MEDIUM as any}
                    onClick={() => {
                      setActiveStepIndexOnboarding(activeStepIndexOnboarding - 1);
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    kind={Button.kinds?.PRIMARY as any}
                    size={Button.sizes?.MEDIUM as any}
                    onClick={() => {
                      setActiveStepIndexOnboarding(activeStepIndexOnboarding + 1);
                    }}
                  >
                    Next
                  </Button>
                </Flex>
              </div>
            </div>,
            <div>
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  height: '200px',
                  backgroundColor: '#f6f7fb',
                  lineHeight: 0,
                }}
              >
                <div
                  style={{
                    pointerEvents: 'none',
                    position: 'relative',
                  }}
                >
                  <img src="/introduction-spreadsheet-formats.png" width="640" height="200" alt="" />
                  <div
                    style={{
                      position: 'absolute',
                      top: '70%',
                      right: 0,
                      bottom: 0,
                      left: 0,
                      background: 'linear-gradient(0deg, rgba(246,247,251,1) 0%, rgba(246,247,251,0) 100%)',
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  padding: '32px 54px',
                  textAlign: 'center',
                }}
              >
                <Flex gap={10} justify={Flex.justify?.CENTER}>
                  <h2>Spreadsheet formats</h2>
                </Flex>
                <p>
                  All created spreadsheets are build upon spreadsheet templates. There are a couple of different target formats available:
                  Create and save either in-app spreadsheets or download them as Excel, CSV and HTML files.
                </p>
              </div>
              <div
                style={{
                  position: 'absolute',
                  bottom: '32px',
                  left: '32px',
                }}
              >
                <Button
                  kind={Button.kinds?.TERTIARY as any}
                  size={Button.sizes?.MEDIUM as any}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Skip
                </Button>
              </div>
              <div
                style={{
                  position: 'absolute',
                  right: '32px',
                  bottom: '32px',
                }}
              >
                <Flex gap={12}>
                  <Button
                    kind={Button.kinds?.TERTIARY as any}
                    size={Button.sizes?.MEDIUM as any}
                    onClick={() => {
                      setActiveStepIndexOnboarding(activeStepIndexOnboarding - 1);
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    kind={Button.kinds?.PRIMARY as any}
                    size={Button.sizes?.MEDIUM as any}
                    onClick={() => {
                      setActiveStepIndexOnboarding(activeStepIndexOnboarding + 1);
                    }}
                  >
                    Next
                  </Button>
                </Flex>
              </div>
            </div>,
            <div>
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  height: '200px',
                  backgroundColor: '#f6f7fb',
                  lineHeight: 0,
                }}
              >
                <div
                  style={{
                    pointerEvents: 'none',
                    position: 'relative',
                  }}
                >
                  <img src="/introduction-automations2.png" width="640" height="200" alt="" />
                  <div
                    style={{
                      position: 'absolute',
                      top: '70%',
                      right: 0,
                      bottom: 0,
                      left: 0,
                      background: 'linear-gradient(0deg, rgba(246,247,251,1) 0%, rgba(246,247,251,0) 100%)',
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  padding: '32px 54px',
                  textAlign: 'center',
                }}
              >
                <Flex gap={10} justify={Flex.justify?.CENTER}>
                  <h2>Automations</h2>
                </Flex>
                <p>
                  Create spreadsheets from templates and email them as embedded table or Excel attachment. Send monday.com notifications
                  containing one or multiple calculated values based on formulas referencing board items.
                </p>
              </div>
              <div
                style={{
                  position: 'absolute',
                  bottom: '32px',
                  left: '32px',
                }}
              >
                <Button
                  kind={Button.kinds?.TERTIARY as any}
                  size={Button.sizes?.MEDIUM as any}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Skip
                </Button>
              </div>
              <div
                style={{
                  position: 'absolute',
                  right: '32px',
                  bottom: '32px',
                }}
              >
                <Flex gap={12}>
                  <Button
                    kind={Button.kinds?.TERTIARY as any}
                    size={Button.sizes?.MEDIUM as any}
                    onClick={() => {
                      setActiveStepIndexOnboarding(activeStepIndexOnboarding - 1);
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    kind={Button.kinds?.PRIMARY as any}
                    size={Button.sizes?.MEDIUM as any}
                    onClick={() => {
                      setActiveStepIndexOnboarding(activeStepIndexOnboarding + 1);
                    }}
                  >
                    Next
                  </Button>
                </Flex>
              </div>
            </div>,
            <div>
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  height: '200px',
                  backgroundColor: '#f6f7fb',
                  lineHeight: 0,
                }}
              >
                <div
                  style={{
                    pointerEvents: 'none',
                    position: 'relative',
                  }}
                >
                  <img src="/introduction-welcome-5.png" width="640" height="200" alt="" />
                </div>
              </div>
              <div
                style={{
                  padding: '32px 54px',
                  textAlign: 'center',
                }}
              >
                <Flex gap={10} justify={Flex.justify?.CENTER}>
                  <h2>That's a wrap</h2>
                </Flex>
                <p>
                  You should now have a better understanding of the app concept and its core features. Feel free to reach out to learn about
                  your requirements, answer questions, and review ways we can help your organization.
                </p>
              </div>
              <div
                style={{
                  position: 'absolute',
                  bottom: '32px',
                  left: '32px',
                }}
              >
                <Button
                  kind={Button.kinds?.TERTIARY as any}
                  size={Button.sizes?.MEDIUM as any}
                  onClick={() => window.open('https://calendly.com/d/z5z-yb9-bxw/30min', '_blank')}
                >
                  Book a demo
                </Button>
              </div>
              <div
                style={{
                  position: 'absolute',
                  right: '32px',
                  bottom: '32px',
                }}
              >
                <Flex gap={12}>
                  <Button
                    kind={Button.kinds?.TERTIARY as any}
                    size={Button.sizes?.MEDIUM as any}
                    onClick={() => {
                      setActiveStepIndexOnboarding(activeStepIndexOnboarding - 1);
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    kind={Button.kinds?.PRIMARY as any}
                    size={Button.sizes?.MEDIUM as any}
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Got it
                  </Button>
                </Flex>
              </div>
            </div>,
          ]}
        />
        */}
      </ModalContent>
    </Modal>
  );
}
