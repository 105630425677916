import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import { Button, Box, Flex, Modal, ModalHeader, ModalContent, ModalFooter } from 'monday-ui-react-core';
import { SingleBoardPicker } from '../shared/components/SingleBoardPicker';

type BoardPickerModalProps = {
  show: boolean;
  onClose: (boardId?: number) => void;
};

export function BoardPickerModal({ show, onClose }: BoardPickerModalProps) {
  const [isViewportHeightBelow500, setIsViewportHeightBelow500] = useState<boolean>(window.innerHeight < 500);

  useEffect(() => {
    const handleResize = () => {
      setIsViewportHeightBelow500(window.innerHeight < 500);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!show) {
    return null;
  }

  return (
    <Modal
      show={show}
      onClose={() => {
        onClose();
      }}
      width={500 as any}
      alertDialog={false}
      hideCloseButton={true}
      data-testid="board-picker-modal"
    >
      <ModalHeader title="" hideCloseButton={true} data-testid="board-picker-modal-header" />
      <ModalContent>
        <Box marginTop={Box.marginTops?.SMALL as any}>
          <SingleBoardPicker
            onBoardSelect={(boardId) => {
              onClose(boardId);
            }}
          />
        </Box>
      </ModalContent>
      <ModalFooter>
        <>
          {!isViewportHeightBelow500 && (
            <Flex gap={12} justify={Flex.justify?.END as any}>
              <Box marginEnd={Box.marginEnds?.AUTO as any}>
                <div style={{ lineHeight: '20px', color: '#afb0bd' }}>
                  Search for boards to append to your current template.{' '}
                  <strong>
                    Do you
                    <br />
                    want to append subitems?
                  </strong>{' '}
                  Simply search for <strong>"Subitems of"</strong>.
                </div>
              </Box>
              <Button
                kind={Button.kinds?.SECONDARY}
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>
            </Flex>
          )}
        </>
      </ModalFooter>
    </Modal>
  );
}
