import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import * as XLSX from 'xlsx-js-style';
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Button,
  Box,
  Flex,
  Checkbox,
  TextField,
  Icon,
  IconButton,
  Label,
} from 'monday-ui-react-core';
// @ts-ignore
import ExternalPageIcon from 'monday-ui-react-core/dist/icons/ExternalPage';
// @ts-ignore
import TurnIntoIcon from 'monday-ui-react-core/dist/icons/TurnInto';
// @ts-ignore
import ColumnIcon from 'monday-ui-react-core/dist/icons/Column';
// @ts-ignore
import ItemIcon from 'monday-ui-react-core/dist/icons/Item';
// @ts-ignore
import BoardIcon from 'monday-ui-react-core/dist/icons/Board';
// @ts-ignore
import FormulaIcon from 'monday-ui-react-core/dist/icons/Formula';
// @ts-ignore
import AlertIcon from 'monday-ui-react-core/dist/icons/Alert';
// @ts-ignore
import MoveArrowRightIcon from 'monday-ui-react-core/dist/icons/MoveArrowRight';
// @ts-ignore
import Dropdown from 'monday-ui-react-core/dist/Dropdown';
import { Template, Sheet, FieldGroup } from '@gorilla/spreadsheet-shared/src/lib/spreadsheet-manager/types';
import { chatatABC } from '@gorilla/spreadsheet-shared/src/lib/spreadsheet-manager/spreadsheet-manager';
import { useSpreadsheetLoader } from '../hooks/use-spreadsheet-loader';
import { Board, updateItemValue } from '@gorilla/common/src/lib/monday-api/api';
import { useExecutionQueue } from '../shared/hooks/use-execution-queue';
import { useBoard } from '../shared/hooks/use-boards';
import useMonday from '../hooks/use-monday';
import { QueueItem } from '../shared/utils/execution-queue';
import { createSignal } from '../services/signals';
import { monday, mondayClient } from '../services/monday';
import { EngineBoardItem } from '@gorilla/common/src/lib/engine/engine';

interface DiffResult {
  itemId: number;
  itemName: string;
  prevValue: string | number | undefined;
  targetValue: string | number | undefined;
  targetColumnTitle: string | undefined;
  type: 'changed' | 'unchanged' | 'updating' | 'updated';
}

function prepareDiffData(
  workbook: XLSX.WorkBook,
  sheet: Sheet,
  fieldGroup: FieldGroup,
  formulaColumnIdx: number,
  targetColumnId: string,
  targetBoard: Board
) {
  const sheetjsSheet = Object.values(workbook.Sheets).find((s) => s['!sheetId'] === sheet.id);

  if (!sheetjsSheet) {
    throw new Error('Worksheet not found');
  }

  const startRowIdx = sheetjsSheet[`!field_group_${fieldGroup.id}_start_row_idx`] as number;
  const endRowIdx = sheetjsSheet[`!field_group_${fieldGroup.id}_end_row_idx`] as number;

  if (!_.isNumber(startRowIdx) || !_.isNumber(endRowIdx)) {
    return [];
  }

  const targetColumn = targetBoard.columns.find((column) => column.id === targetColumnId);

  const results: DiffResult[] = [];

  for (let rowIdx = startRowIdx; rowIdx <= endRowIdx; rowIdx++) {
    const formulaCell = sheetjsSheet[`${chatatABC(formulaColumnIdx)}${rowIdx + 1}`];
    const boardItem = sheetjsSheet[`!row_${rowIdx}_item`] as EngineBoardItem;

    const prevBoardItemValue = boardItem.values.find((value) => {
      return value.column_id === targetColumnId;
    });

    const prevValue = _.get(prevBoardItemValue, 'value.value');
    const targetValue = formulaCell?.v;

    results.push({
      itemId: boardItem.id,
      itemName: boardItem.name,
      prevValue: prevValue,
      targetValue: targetValue,
      targetColumnTitle: targetColumn?.title,
      type: prevValue === targetValue ? 'unchanged' : 'changed',
    });
  }

  return results;
}

interface ToMondayModalContext {
  targetColumnId?: string;
  onlyChangedValues?: boolean;
  isQuickAction?: boolean;
  quickActionName?: string;
}

type Path = string[];

type DiffTableRowProps = {
  diffResult: DiffResult;
  onUpdateClick: () => void;
  updateStatus: 'pending' | 'resolved' | 'rejected' | null;
  updateError: string | null;
};

function DiffTableRow({ diffResult, onUpdateClick, updateStatus, updateError }: DiffTableRowProps) {
  let status: any;

  if (updateStatus === 'pending') {
    status = <Label text="Updating item" isAnimationDisabled color={Label.colors?.DARK} />;
  } else if (updateStatus === 'resolved') {
    status = <Label text="Value updated" isAnimationDisabled color={Label.colors?.POSITIVE} />;
  } else if (updateStatus === 'rejected') {
    status = <Label text={`Error: ${updateError}`} isAnimationDisabled color={Label.colors?.NEGATIVE} />;
  } else if (diffResult.type === 'changed') {
    status = <Label text="Update available" isAnimationDisabled />;
  } else if (diffResult.type === 'unchanged') {
    status = <Label text="Values match" isAnimationDisabled color={Label.colors?.DARK} kind={Label.kinds?.LINE} />;
  }

  let diff: any = null;

  if (diffResult.prevValue === diffResult.targetValue) {
    diff = (
      <>
        <td className="before" style={{ color: 'rgba(50, 51, 56, var(--disabled-component-opacity))' }}>
          {diffResult.prevValue}
        </td>
        <td className="operator" style={{ color: 'rgba(50, 51, 56, var(--disabled-component-opacity))' }}>
          <Flex justify={Flex.justify?.CENTER}>
            <Icon icon={MoveArrowRightIcon} clickable={false} />
          </Flex>
        </td>
        <td className="after" style={{ color: 'rgba(50, 51, 56, var(--disabled-component-opacity))' }}>
          <strong>{diffResult.targetValue}</strong>
        </td>
      </>
    );
  } else if (updateStatus === 'resolved') {
    diff = (
      <>
        <td className="before" style={{ textDecoration: 'line-through', color: '#d83a52' }}>
          {diffResult.prevValue}
        </td>
        <td className="operator">
          <Flex justify={Flex.justify?.CENTER}>
            <Icon icon={MoveArrowRightIcon} clickable={false} />
          </Flex>
        </td>
        <td className="after" style={{ color: '#258750' }}>
          <strong>{diffResult.targetValue}</strong>
        </td>
      </>
    );
  } else {
    diff = (
      <>
        <td className="before">
          {diffResult.prevValue === null || diffResult.prevValue === null ? <span>&nbsp;</span> : <span>{diffResult.prevValue}</span>}
        </td>
        <td className="operator">
          <Flex justify={Flex.justify?.CENTER}>
            <Icon icon={MoveArrowRightIcon} clickable={false} />
          </Flex>
        </td>
        <td className="after">
          {diffResult.targetValue === null || diffResult.targetValue === undefined ? (
            <span>&nbsp;</span>
          ) : (
            <strong>{diffResult.targetValue}</strong>
          )}
        </td>
      </>
    );
  }

  return (
    <tr className={`${diffResult.type === 'unchanged' ? 'unchanged' : ''}`}>
      <td>
        <Flex gap={12}>
          <IconButton
            icon={ExternalPageIcon}
            size={Button.sizes?.XS as any}
            kind={Button.kinds?.TERTIARY as any}
            onClick={() => {
              monday.execute('openItemCard', {
                itemId: diffResult.itemId,
              });
            }}
          />
          <span>{diffResult.itemName}</span>
          {status}
        </Flex>
      </td>
      <td className="colname">
        <span>{diffResult.targetColumnTitle}</span>
      </td>
      {diff}
      <td className="action">
        <Flex gap={12} justify={Flex.justify?.CENTER as any}>
          <Button
            disabled={diffResult.type !== 'changed' || !!updateStatus}
            size={Button.sizes?.XS as any}
            kind={Button.kinds?.SECONDARY as any}
            onClick={async () => {
              createSignal('to_monday_update');
              onUpdateClick();
            }}
          >
            Update
          </Button>
        </Flex>
      </td>
    </tr>
  );
}

type DiffTableProps = {
  diffData: ReturnType<typeof prepareDiffData>;
  queue: Map<string | number, QueueItem>;
  onUpdateClick: (diffResult: DiffResult) => void;
  onlyChangedValues: boolean;
  onChangeOnlyChangedValues: (onlyChangedValues: boolean) => void;
  targetColumnId: string;
  targetBoard: Board;
};

function DiffTable({
  diffData,
  onChangeOnlyChangedValues,
  onlyChangedValues,
  targetBoard,
  targetColumnId,
  queue,
  onUpdateClick,
}: DiffTableProps) {
  if (diffData.length === 0) {
    return <div>Oops, no items found.</div>;
  }

  const filteredDiffData = onlyChangedValues ? diffData.filter((diffResult) => diffResult.type !== 'unchanged') : diffData;

  return (
    <div>
      <table className="ToMondayTable">
        <thead>
          <tr>
            <th>
              <Flex gap={8} align={Flex.align?.CENTER}>
                <Icon icon={ItemIcon} iconSize={20} clickable={false} />
                <span>Item name</span>
              </Flex>
            </th>
            <th className="colname">
              <Flex gap={8} align={Flex.align?.CENTER} justify={Flex.justify?.CENTER}>
                <Icon icon={ColumnIcon} iconSize={20} clickable={false} />
                <span>Column</span>
              </Flex>
            </th>
            <th className="before">
              <Flex gap={8} align={Flex.align?.CENTER} justify={Flex.justify?.CENTER}>
                <Icon icon={ColumnIcon} iconSize={20} clickable={false} />
                <span>Value before</span>
              </Flex>
            </th>
            <th className="operator">&nbsp;</th>
            <th className="after">
              <Flex gap={8} align={Flex.align?.CENTER} justify={Flex.justify?.CENTER}>
                <Icon icon={FormulaIcon} iconSize={20} clickable={false} />
                <span>Value after</span>
              </Flex>
            </th>
            <th className="action">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {filteredDiffData.length === 0 ? (
            <tr>
              <td colSpan={6} rowSpan={2} style={{ textAlign: 'center' }}>
                No items match the filter criteria.
              </td>
            </tr>
          ) : null}

          {filteredDiffData.map((diffResult, i) => {
            const queueItem = queue.get(diffResult.itemId);
            let updateError: string | null = null;

            if (_.get(queueItem, 'error.data.errorCode') === 'ColumnValueException') {
              updateError = 'mismatch between column type and value type';
            } else if (_.get(queueItem, 'error.data.errorMessage')) {
              updateError = _.get(queueItem, 'error.data.errorMessage')!;
            } else if (_.get(queueItem, 'error.message')) {
              updateError = _.get(queueItem, 'error.message')!;
            } else if (_.get(queueItem, 'error')) {
              updateError = 'unknown error';
            }

            return (
              <DiffTableRow
                key={diffResult.itemId}
                updateStatus={queueItem ? queueItem.status : null}
                updateError={updateError}
                diffResult={diffResult}
                onUpdateClick={() => {
                  onUpdateClick(diffResult);
                }}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

type SummaryStepProps = {
  onClose: () => void;
  ctx: ToMondayModalContext;
  onCtxChange: (ctx: ToMondayModalContext) => void;
  onPathChange?: (path: Path) => void;
  formulaColumnIdx: number;
  template: Template;
  targetBoard: Board;
  fieldGroup: FieldGroup;
  showBackButton: boolean;
  sheet: Sheet;
  updateAllOnLoad?: boolean;
  setModalFullWidth: any;
};

function SummaryStep({
  ctx,
  onClose,
  onCtxChange,
  template,
  onPathChange,
  fieldGroup,
  sheet,
  formulaColumnIdx,
  targetBoard,
  setModalFullWidth,
  updateAllOnLoad = false,
  showBackButton = false,
}: SummaryStepProps) {
  const { loadingMessage, data, error } = useSpreadsheetLoader(_.get(template, 'id'), 'sheetjs', true, false);
  const { add, queueSize, isRunning, queue, getQueueDonePromise } = useExecutionQueue();
  let diffData: ReturnType<typeof prepareDiffData> | null = null;

  if (data) {
    diffData = prepareDiffData(data as XLSX.WorkBook, sheet, fieldGroup, formulaColumnIdx, ctx.targetColumnId as string, targetBoard);
  }

  let changedDiffData: ReturnType<typeof prepareDiffData> | null = null;

  if (diffData) {
    changedDiffData = diffData.filter((diffResult) => diffResult.type === 'changed');
  }

  function updateAll() {
    if (changedDiffData) {
      changedDiffData.forEach((diffResult) => {
        add(diffResult.itemId, async () => {
          await updateItemValue(mondayClient, targetBoard.id, diffResult.itemId, ctx.targetColumnId!, `${diffResult.targetValue}`);
        });
      });
    }
  }

  const allUpdating = !!(changedDiffData && changedDiffData.length === queue.size);

  useEffect(() => {
    setModalFullWidth(true);

    if (data) {
      (async () => {
        if (updateAllOnLoad) {
          updateAll();
          await getQueueDonePromise();
          onClose();
        }
      })();
    }
  }, [data]);

  return (
    <>
      <ModalContent>
        <div
          style={{
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 240px)',
            border: '1px solid #d0d4e4',
            borderRadius: '8px',
          }}
        >
          <div>
            {diffData ? (
              <DiffTable
                queue={queue}
                diffData={diffData}
                onlyChangedValues={!!ctx.onlyChangedValues}
                onChangeOnlyChangedValues={(onlyChangedValues) => {
                  onCtxChange({ ...ctx, onlyChangedValues });
                }}
                targetColumnId={ctx.targetColumnId as string}
                targetBoard={targetBoard}
                onUpdateClick={(diffResult) => {
                  add(diffResult.itemId, async () => {
                    await updateItemValue(
                      mondayClient,
                      targetBoard.id,
                      diffResult.itemId,
                      ctx.targetColumnId!,
                      `${diffResult.targetValue}`
                    );
                  });
                }}
              />
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  minHeight: '178px',
                }}
              >
                {loadingMessage}
              </div>
            )}
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <Flex>
          <Flex gap={12}>
            <Checkbox
              label="Only show items with available updates"
              disabled={!changedDiffData || changedDiffData.length === 0}
              checked={!!ctx.onlyChangedValues}
              onChange={(ev) => {
                onCtxChange({ ...ctx, onlyChangedValues: ev.target.checked });
              }}
            />
          </Flex>
          <Box marginStart={Box.marginStarts?.AUTO as any}>
            <Flex gap={12} justify={Flex.justify?.END as any}>
              {/* 
              <Button
                kind={Button.kinds?.TERTIARY as any}
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>
              */}

              {/* disabled={!diffData || diffData.filter((diffResult) => diffResult.type === 'changed').length === 0} */}

              {showBackButton ? (
                <Button
                  kind={Button.kinds?.TERTIARY as any}
                  onClick={() => {
                    if (onPathChange) {
                      onPathChange([]);
                    }
                    setModalFullWidth(false);
                  }}
                >
                  Back
                </Button>
              ) : null}
              <Button
                disabled={allUpdating}
                loading={isRunning}
                onClick={async () => {
                  createSignal('to_monday_update_all');
                  updateAll();
                }}
              >
                Update all
              </Button>
            </Flex>
          </Box>
        </Flex>
      </ModalFooter>
    </>
  );
}

type BoardColumnSelectionStepProps = {
  onClose: () => void;
  ctx: ToMondayModalContext;
  onCtxChange: (ctx: ToMondayModalContext) => void;
  onPathChange: (path: Path) => void;
  onSaveSettings: () => void;

  formulaColumnIdx: number;
  template: Template;
  targetBoard: Board;

  path: Path;
  formula: string;

  setModalFullWidth;
};

function BoardColumnSelectionStep({
  ctx,
  path,
  onSaveSettings,
  onClose,
  onCtxChange,
  onPathChange,
  formulaColumnIdx,
  template,
  targetBoard,
  formula,
  setModalFullWidth,
}: BoardColumnSelectionStepProps) {
  const columnOptions = targetBoard.columns
    .filter((column) => column.type === 'text' || column.type === 'long_text' || column.type === 'numbers')
    .map((column) => ({
      label: column.title,
      value: column.id,
      leftIcon: ColumnIcon,
    }));

  const selectedColumnOption = columnOptions.find((option) => option.value === ctx.targetColumnId);

  return (
    <>
      <ModalContent>
        <Box
          marginTop={Box.marginTops?.MEDIUM as any}
          padding={Box.paddings?.MEDIUM as any}
          backgroundColor={Box.backgroundColors?.ALL_GREY_BACKGROUND_COLOR as any}
          rounded={Box.roundeds?.SMALL as any}
          border={Box.borders?.DEFAULT as any}
        >
          <>
            <div
              style={{
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                marginBottom: '10px',
                padding: '7px 17px',
                borderRadius: '4px',
                border: '1px solid #c3c6d4',
                backgroundColor: '#fff',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <Icon icon={FormulaIcon} clickable={false} /> Formula
              </div>
              <div
                style={{
                  flex: 1,
                  textAlign: 'right',
                }}
              >
                <strong>{formula}</strong>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                marginBottom: '10px',
                padding: '7px 17px',
                borderRadius: '4px',
                border: '1px solid #c3c6d4',
                backgroundColor: '#fff',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <Icon icon={BoardIcon} clickable={false} /> Target board
              </div>
              <div
                style={{
                  flex: 1,
                  textAlign: 'right',
                }}
              >
                <strong>{targetBoard.name}</strong>
              </div>
            </div>
            <Dropdown
              onOptionSelect={(ev: any) => {
                onCtxChange({ ...ctx, targetColumnId: ev.value });
              }}
              options={columnOptions}
              placeholder={`Select board column to update`}
              insideOverflowContainer
              value={selectedColumnOption}
              clearable={false}
              size={Dropdown.size.MEDIUM}
            />
            <div style={{ margin: '15px 0' }}>
              <Checkbox
                label="Use as Quick Action: Update items right from your dashboard"
                checked={!!ctx.isQuickAction}
                onChange={(ev) => {
                  onCtxChange({ ...ctx, isQuickAction: ev.target.checked });
                }}
              />
            </div>
            {ctx.isQuickAction ? (
              <div style={{ marginBottom: '10px' }}>
                <TextField
                  placeholder="e.g. Run Calculation"
                  size={TextField.sizes.MEDIUM}
                  value={ctx.quickActionName}
                  maxLength={20}
                  onChange={(newVal) => {
                    onCtxChange({ ...ctx, quickActionName: newVal });
                  }}
                />
              </div>
            ) : null}
            <div>
              <Button
                kind={Button.kinds?.SECONDARY as any}
                onClick={() => {
                  onSaveSettings();
                }}
              >
                Save Settings
              </Button>
            </div>
          </>
        </Box>
      </ModalContent>
      <ModalFooter>
        <Flex gap={5} justify={Flex.justify?.END as any}>
          <Box marginEnd={Box.marginEnds?.AUTO as any}>
            <div style={{ fontSize: '14px', color: '#afb0bd' }}>
              Only <em>text</em> and <em>numeric</em> columns are currently supported.
            </div>
          </Box>
          <Flex>
            <Box marginStart={Box.marginStarts?.AUTO as any}>
              <Flex gap={12} justify={Flex.justify?.END as any}>
                <Button
                  kind={Button.kinds?.TERTIARY as any}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!ctx.targetColumnId}
                  onClick={() => {
                    onPathChange(['configuration']);

                    const localStorageKey = `to-monday-modal-column::${template.id}::${formulaColumnIdx}`;
                    localStorage.setItem(localStorageKey, ctx.targetColumnId!);
                  }}
                >
                  Next
                </Button>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </ModalFooter>
    </>
  );
}

type ToMondaySettings = {
  to_monday_target_column_id?: string;
  to_monday_is_quick_actions?: boolean;
  to_monday_quick_action_name?: string;
};

type ToMondayModalProps = {
  onClose: () => void;
  onSaveSettings: (settings: ToMondaySettings) => void;
  show: boolean;
  initialCtx?: ToMondayModalContext;

  formulaColumnIdx: number;
  template: Template;
  targetBoard: Board;
  fieldGroup: FieldGroup;
  sheet: Sheet;
  formula: string;
};

export function ToMondayModal({
  show,
  onClose,
  initialCtx,
  formulaColumnIdx,
  formula,
  template,
  targetBoard,
  fieldGroup,
  onSaveSettings,
  sheet,
}: ToMondayModalProps) {
  const [path, setPath] = useState<Path>([]);
  const [ctx, setCtx] = useState<ToMondayModalContext>(initialCtx || {});
  const [modalFullWidth, setModalFullWidth] = useState(false);
  const { mondayContext } = useMonday();

  let modalHeader: React.ReactElement | null = null;
  let modalContent = <></>;

  if (import.meta.env.MODE !== 'development' && _.get(mondayContext, 'appVersion.versionData.major')! < 4) {
    modalHeader = (
      <ModalHeader
        title={`Update board items`}
        closeButtonAriaLabel=""
        hideCloseButton={false}
        description={
          'The app needs permission to update boards for this functionality to work. Please ask an admin of your monday account to update the app.'
        }
        icon={AlertIcon}
      />
    );
  } else if (path.length === 0) {
    modalHeader = (
      <ModalHeader
        title={`Update board items`}
        closeButtonAriaLabel=""
        hideCloseButton={true}
        description={
          'The formula below will be executed in the target board for each item in the column you select. In the next step you can review all values before applying any changes.'
        }
        icon={TurnIntoIcon}
      />
    );

    modalContent = (
      <BoardColumnSelectionStep
        onClose={onClose}
        ctx={ctx}
        onCtxChange={(ctx) => setCtx(ctx)}
        onPathChange={(path) => setPath(path)}
        template={template}
        targetBoard={targetBoard}
        onSaveSettings={() => {
          onSaveSettings({
            to_monday_target_column_id: ctx.targetColumnId,
            to_monday_is_quick_actions: ctx.isQuickAction,
            to_monday_quick_action_name: ctx.quickActionName,
          });
        }}
        formula={formula}
        formulaColumnIdx={formulaColumnIdx}
        path={path}
        setModalFullWidth={setModalFullWidth}
      />
    );
  } else if (path.length === 1) {
    modalHeader = (
      <ModalHeader title={`Update board items`} closeButtonAriaLabel="" hideCloseButton={false} description={''} icon={TurnIntoIcon} />
    );

    modalContent = (
      <SummaryStep
        onClose={onClose}
        ctx={ctx}
        onCtxChange={(ctx) => setCtx(ctx)}
        onPathChange={(path) => setPath(path)}
        showBackButton={true}
        template={template}
        targetBoard={targetBoard}
        formulaColumnIdx={formulaColumnIdx}
        fieldGroup={fieldGroup}
        sheet={sheet}
        setModalFullWidth={setModalFullWidth}
      />
    );
  }

  return (
    <Modal
      show={show}
      onClose={() => onClose()}
      width={modalFullWidth ? Modal.width?.FULL_WIDTH : (Modal.width?.DEFAULT as any)}
      alertDialog={true}
      data-testid="to-monday-modal"
    >
      {modalHeader as React.ReactElement}
      {modalContent}
    </Modal>
  );
}

type InstantUpdateModalProps = {
  onClose: () => void;
  show: boolean;
  initialCtx?: ToMondayModalContext;

  formulaColumnIdx: number;
  template: Template;
  fieldGroup: FieldGroup;
  sheet: Sheet;
};

export function InstantUpdateModal({ show, onClose, initialCtx, formulaColumnIdx, template, fieldGroup, sheet }: InstantUpdateModalProps) {
  const [ctx, setCtx] = useState<ToMondayModalContext>(initialCtx || {});
  const [modalFullWidth, setModalFullWidth] = useState(false);
  const { board, isLoading, error } = useBoard(fieldGroup.board_id);
  let content: any = null;

  if (isLoading) {
    content = <ModalContent>Loading</ModalContent>;
  } else if (fieldGroup.from_context) {
    content = <ModalContent>Error: Dynamic boards not supported</ModalContent>;
  } else if (error) {
    content = <ModalContent>Error: {error?.message}</ModalContent>;
  } else if (!board) {
    content = <ModalContent>Error: Could not load board</ModalContent>;
  } else {
    content = (
      <SummaryStep
        onClose={onClose}
        ctx={ctx}
        onCtxChange={(ctx) => setCtx(ctx)}
        // not needed
        template={template}
        targetBoard={board}
        formulaColumnIdx={formulaColumnIdx}
        fieldGroup={fieldGroup}
        sheet={sheet}
        showBackButton={false}
        updateAllOnLoad={false}
        setModalFullWidth={setModalFullWidth}
      />
    );
  }

  return (
    <Modal
      show={show}
      onClose={() => onClose()}
      width={modalFullWidth ? Modal.width?.FULL_WIDTH : (Modal.width?.DEFAULT as any)}
      alertDialog={true}
      data-testid="instant-update-modal"
    >
      <ModalHeader title="Update board items" closeButtonAriaLabel="" hideCloseButton={false} description={''} icon={TurnIntoIcon} />
      {content}
    </Modal>
  );
}
